import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { PRICE_COEFFICIENTS } from '../../../shared/constants';
import {
  CoefficientType,
  PercentCoefficientType,
} from '../../../shared/types';
import {
  ImportHeaderMapping,
  ImportType,
} from '../../../shared/types/import';

export type State = {
  headerMapping: ImportHeaderMapping;
};

type Actions = {
  getHeaderMapping: <T extends ImportType>(
    type: T,
  ) => State['headerMapping'][T];
  setHeaderMapping: (
    type: ImportType,
    key: string,
    value: string,
  ) => void;
};

export const INIT_COEFFICIENT_HEADER_MAPPING =
  PRICE_COEFFICIENTS.reduce(
    (acc, { key }) => ({
      ...acc,
      [key]: key,
    }),
    {} as Record<CoefficientType, string>,
  );

export const INIT_INDUSTRY_PERCENT_HEADER_MAPPING =
  PRICE_COEFFICIENTS.reduce(
    (acc, { industryPercentKey }) => ({
      ...acc,
      [industryPercentKey]: industryPercentKey,
    }),
    {} as Record<PercentCoefficientType, string>,
  );

export const useImportStore = create<State & Actions>()(
  immer((set, get) => ({
    headerMapping: {
      [ImportType.Common]: {
        rentalReadyPrice: 'rentalReadyPrice',
        targetUtilRate: 'targetUtilRate',
        depreciationPeriod: 'depreciationPeriod',
        avgRentalPeriod: 'avgRentalPeriod',
        requiredRateOfReturn: 'requiredRateOfReturn',
        totalBalance: 'totalBalance',
      },
      [ImportType.CriticalEquipment]: {
        'criticalEquipmentItems.minPriceDay': 'minPriceDay',
        'criticalEquipmentItems.minPriceMonth': 'minPriceMonth',
        'criticalEquipmentItems.from': 'from',
        'criticalEquipmentItems.to': 'to',
      },
      [ImportType.SurplusEquipment]: {
        'surplusEquipmentItem.changePercentage': 'changePercentage',
        'surplusEquipmentItem.surplusFrom': 'surplusFrom',
        'surplusEquipmentItem.surplusTo': 'surplusTo',
      },
      [ImportType.Coefficient]: INIT_COEFFICIENT_HEADER_MAPPING,
      [ImportType.IndustryPercent]:
        INIT_INDUSTRY_PERCENT_HEADER_MAPPING,
    },
    getHeaderMapping: (type) => get().headerMapping[type],
    setHeaderMapping: (type, key, value) => {
      set((state) => {
        state.headerMapping[type][key] = value;
      });
    },
  })),
);
