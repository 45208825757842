import { api } from '..';
import {
  CreateProductListRequestBody,
  CreateProductListResponseBody,
  UpdateProductListRequestBody,
  UpdateProductListResponseBody,
} from '../../../shared/types/productLists';

/**
 * CREATE PRODUCT LIST
 */
export const createProductListRequest = async (
  productList: CreateProductListRequestBody,
): Promise<CreateProductListResponseBody> => {
  const { data } = await api.post<CreateProductListResponseBody>(
    '/productlists',
    productList,
  );
  return data;
};

/**
 * UPDATE PRODUCT LIST
 */
export const updateProductListRequest = async (
  productList: UpdateProductListRequestBody,
): Promise<UpdateProductListResponseBody> => {
  const { data } = await api.patch<UpdateProductListResponseBody>(
    `/productlists/${productList.id}`,
    productList,
  );
  return data;
};

/**
 * DELETE PRODUCT LIST
 */
export const deleteProductListRequest = async (
  id: string,
): Promise<UpdateProductListResponseBody> => {
  const { data } = await api.delete<UpdateProductListResponseBody>(
    `/productlists/${id}`,
  );
  return data;
};
