import { PricingSheetRow } from '../../../shared/types';
import { countRemovalDayPrice } from '../../../shared/countRemovalDayPrice';
import { countRemovalMonthPrice } from '../../../shared/countRemovalMonthPrice';
import { roundToTwoDecimal } from '../../../shared/roundToTwoDecimal';

import { PRICE_FACTOR } from '../../../shared/constants';
import { calculateTotalPrice } from '../../../shared/calculateTotalPrice';
import { isSalesItem } from '../../../shared/helpers/isSalesItem';
import { calculateTargetUtilRate } from '../../../shared/countOfferPrice';
import { getSurplusRange } from '../../../shared/getSurplusRange';
import { countPriceCoefficient } from '../../../shared/utils/countPriceCoefficient';

export const COEFFICIENT_LIMIT_LIGHT = 1.2; // orange rows
export const COEFFICIENT_LIMIT = 1; // red rows
export const DISCOUNT_LIMIT = 0.7; // pink rows

export const WARNING_THRESHOLD = 5;

export const getCoefficient = (
  price: number | null | undefined,
  removalPrice: number | null,
) => {
  return removalPrice
    ? roundToTwoDecimal(Number(price) / removalPrice)
    : null;
};

export const getRemovalDayPrice = (row: PricingSheetRow) =>
  countRemovalDayPrice(
    Number(row.rentalReadyPrice),
    Number(row.depreciationPeriod),
    Number(row.targetUtilRate) / 100,
  );

export const getRemovalMonthPrice = (row: PricingSheetRow) =>
  countRemovalMonthPrice(
    Number(row.rentalReadyPrice),
    Number(row.depreciationPeriod),
    Number(row.targetUtilRate) / 100,
  );

export const getProposalDayCoefficients = (rows: PricingSheetRow[]) =>
  rows.map((row) => {
    const removalDayPrice = countRemovalDayPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    if (row?.proposalDayPrice && removalDayPrice) {
      const priceCoefficientDay = countPriceCoefficient(
        row?.proposalDayPrice / PRICE_FACTOR,
        removalDayPrice,
      );
      return priceCoefficientDay;
    }
    return null;
  });

export const getProposalMonthCoefficients = (
  rows: PricingSheetRow[],
) =>
  rows.map((row) => {
    const removalMonthPrice = countRemovalMonthPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    if (row?.proposalMonthPrice && removalMonthPrice) {
      const priceCoefficientMonth = countPriceCoefficient(
        row?.proposalMonthPrice / PRICE_FACTOR,
        removalMonthPrice,
      );
      return priceCoefficientMonth;
    }
    return null;
  });

const getCoefficientsSummed = (coefficients: (number | null)[]) =>
  coefficients.reduce((current, newCoefficient) => {
    if (current !== null && newCoefficient !== null) {
      return current + newCoefficient;
    }
    return current;
  }, 0);

export const getCoefficientsMeanValue = (
  coefficients: (number | null)[],
) => {
  const sum = getCoefficientsSummed(coefficients);
  return sum ? sum / coefficients.length : null;
};

export const getDayCoefficients = (rows: PricingSheetRow[]) =>
  rows.map((row) => {
    const removalDayPrice = countRemovalDayPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    if (row?.dayPrice && removalDayPrice) {
      const priceCoefficientDay = countPriceCoefficient(
        row?.dayPrice / PRICE_FACTOR,
        removalDayPrice,
      );
      return priceCoefficientDay;
    }
    return null;
  });

export const getMonthCoefficients = (rows: PricingSheetRow[]) =>
  rows.map((row) => {
    const removalMonthPrice = countRemovalMonthPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    if (row?.monthPrice && removalMonthPrice) {
      const priceCoefficintMonth = countPriceCoefficient(
        row?.monthPrice / PRICE_FACTOR,
        removalMonthPrice,
      );
      return priceCoefficintMonth;
    }
    return null;
  });

export const getOfferTotalCoefficients = (items: any[]): number => {
  const totalPrice = items.reduce((acc, item) => {
    if (isSalesItem(item.type)) {
      return acc; // Skip sales items
    }

    return acc + calculateTotalPrice(item, 'TWODECIMALS');
  }, 0);

  let totalPriceWithPriceFactor = 0;

  items.forEach((item) => {
    if (isSalesItem(item.type)) {
      return;
    }
    let priceFactor = null;

    const rentalRange = {
      start: new Date(item.leasePeriodStart),
      end: new Date(item.leasePeriodEnd),
    };

    const surplusRange = getSurplusRange(item, rentalRange);

    const targetUtilRate = calculateTargetUtilRate(
      rentalRange,
      item.avgRentalPeriod,
      item.targetUtilRate,
      surplusRange,
    );

    if (item.pricingBasis === 'DAY') {
      const removalDayPrice = countRemovalDayPrice(
        Number(item?.rentalReadyPrice),
        Number(item?.depreciationPeriod),
        Number(targetUtilRate),
      );
      if (removalDayPrice) {
        priceFactor = countPriceCoefficient(
          item.dayPrice,
          removalDayPrice,
        );
      }
    } else {
      const removalMonthPrice = countRemovalMonthPrice(
        Number(item?.rentalReadyPrice),
        Number(item?.depreciationPeriod),
        Number(targetUtilRate),
      );
      if (removalMonthPrice) {
        priceFactor = countPriceCoefficient(
          item.monthPrice,
          removalMonthPrice,
        );
      }
    }

    if (priceFactor !== null) {
      const total = calculateTotalPrice(item, 'TWODECIMALS');
      totalPriceWithPriceFactor += total * priceFactor;
    }
  });

  const priceWeightedPriceFactor =
    totalPriceWithPriceFactor / totalPrice;

  return Math.round(priceWeightedPriceFactor);
};
