import React from 'react';
import { Typography } from '@mui/material';
import { differenceInDaysString } from '../InitialInformationStep';
import { BackgroundInfo } from '../../../../../shared/types';

interface DurationProps {
  backgroundInfo: BackgroundInfo;
}
const Duration: React.FC<DurationProps> = ({ backgroundInfo }) => {
  return (
    <Typography
      variant="h4"
      component="div"
      gutterBottom
      color="text.primary"
    >
      {differenceInDaysString(
        backgroundInfo?.priceSheetStartDate,
        backgroundInfo?.priceSheetEndDate,
      )}
    </Typography>
  );
};

export default Duration;
