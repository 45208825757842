import React from 'react';
import PriceSheetTypeSelector from './Selectors/PriceSheetTypeSelector';

interface LevelProps {
  handlers: <T>(propName: string) => {
    value: any;
    onChange: (value: T) => void;
  };
}
const Level: React.FC<LevelProps> = ({ handlers }) => {
  return (
    <PriceSheetTypeSelector {...handlers<string>('priceSheetType')} />
  );
};

export default Level;
