import { ProductListItem } from "./productLists";

export type RoundingBasis = "TWODECIMALS" | "SIXDECIMALS" | "THREESIGNIFICANT";
export type PricingBasis = "DAY" | "MONTH" | "BOTH";
export type TableSizeOption = 5 | 10 | 25 | 100;
export type TableDateFilterOperators = "gte" | "lte";
export type Language = "fi" | "en" | "sv";

/**
 * UTILITY TYPES
 */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * COMMON TYPES
 */
export type Error = Record<string, unknown>;

export type TablePaginationOptions = {
  offset: number;
  limit: TableSizeOption;
};

export type TableSortOptions<T> = {
  direction: TableSortOrder;
  orderBy: keyof T;
};

export type TableWithPaginationDataResponse<T> = {
  count: number;
  items: T[];
};

export type DataTableQueryFnParams<
  Type,
  FilterType = string,
> = TablePaginationOptions &
  TableSortOptions<Type> & {
    filter: FilterType;
  };

export type TableFilterOptions<
  T = unknown,
  CustomFilters extends object = {},
> = {
  [K in keyof T]?: T[K];
} & {
  [K in keyof CustomFilters]?: CustomFilters[K];
};

export type TableColumn<T> = {
  key: keyof T;
  label: string;
  width?: string | number;
};

export type RowFieldType =
  | "productGroup"
  | "productLine"
  | "category"
  | "pimProductGroup"
  | "pimProductLine"
  | "pimCategory"
  | "catClass";

export interface DatabaseItemModel {
  id: number;
  catClass: string;
  catClassName: string | null;
  name?: string | null;
  type: ItemType;
  productGroup: string | null;
  productLine: string | null;
  category: string | null;
  rentalReadyPrice: number | null;
  targetUtilRate: number | null;
  depreciationPeriod: number | null;
  dayPriceCoefficient1: number | null;
  dayPriceCoefficient2: number | null;
  dayPriceCoefficient3: number | null;
  dayPriceCoefficient4: number | null;
  dayPriceCoefficient5: number | null;
  dayPriceCoefficient6: number | null;
  dayPriceCoefficient7: number | null;
  dayPriceCoefficientList: number | null;
  monthPriceCoefficient1: number | null;
  monthPriceCoefficient2: number | null;
  monthPriceCoefficient3: number | null;
  monthPriceCoefficient4: number | null;
  monthPriceCoefficient5: number | null;
  monthPriceCoefficient6: number | null;
  monthPriceCoefficient7: number | null;
  monthPriceCoefficientList: number | null;
  includeInPricing: boolean;
  categoryPdf: string | null;
  productGroupPdf: string | null;
  pimProductGroup: string | null;
  pimProductLine: string | null;
  pimCategory: string | null;
  pimPath: string | null;
  pimDisplayName: string | null;
  pimUpdatedAt: Date | null;
  belongsToCompactPreSelection: boolean;
  belongsToLargePreSelection: boolean;
  isRamigreen: boolean;
  removedAt?: Date | null;
  markedAsRemoved: boolean;
  kitName: string | null;
  avgRentalPeriod: number | null;
}

export interface DatabaseItem {
  id: number;
  catClass: string;
  catClassName?: string;
  name?: string | null;
  productGroup: string | null;
  productLine: string | null;
  category: string | null;
  categoryPdf?: string;
  criticalEquipmentItems: CriticalEquipmentItem[];
  surplusEquipmentItem: SurplusEquipmentItem | null;
  productListItems: ProductListItem[];
  productGroupPdf?: string;
  rentalReadyPrice?: string;
  targetUtilRate?: number;
  includeInPricing: boolean;
  depreciationPeriod?: number;
  dayPriceCoefficient1?: number;
  dayPriceCoefficient2?: number;
  dayPriceCoefficient3?: number;
  dayPriceCoefficient4?: number;
  dayPriceCoefficient5?: number;
  dayPriceCoefficient6?: number;
  dayPriceCoefficient7?: number;
  dayPriceCoefficientList?: number;
  monthPriceCoefficient1?: number;
  monthPriceCoefficient2?: number;
  monthPriceCoefficient3?: number;
  monthPriceCoefficient4?: number;
  monthPriceCoefficient5?: number;
  monthPriceCoefficient6?: number;
  monthPriceCoefficient7?: number;
  monthPriceCoefficientList?: number;
  isRamigreen?: boolean;
  removedAt?: Date | null;
  itemIndustryPercents?: any;
  pimProductGroup?: string;
  pimProductLine?: string;
  pimCategory?: string;
  pimDisplayName?: string;
  pimDisplayNameEN?: string;
  pimDisplayNameSE?: string;
  pimPath?: string;
  pimUpdatedAt?: Date;
  belongsToCompactPreSelection?: boolean;
  belongsToLargePreSelection?: boolean;
  dayPrice?: number | null;
  monthPrice?: number | null;
  showByOfferText?: boolean;
  creatorComment?: string | null;
  approverComment?: string | null;
  markedAsRemoved: boolean;
  kitName?: string;
  type?: ItemType;
  quantity: number;
  leasePeriodStart: Date;
  leasePeriodEnd: Date;
  comment?: string | null;
  pricingBasis: PricingBasis;
  avgRentalPeriod?: number;
  unit: string;
  amount?: number;
  requiredRateOfReturn?: number;
  totalBalance?: number;
}

export interface PricingSheetRow extends DatabaseItem {
  prefixPimProductGroup?: string;
  prefixPimProductLine?: string;
  prefixPimCategory?: string;
  prefixProductGroup?: string;
  prefixProductLine?: string;
  prefixCategory?: string;
  proposalDayPrice?: number | null;
  proposalMonthPrice?: number | null;
  comparisonDayPrice?: number | null;
  comparisonMonthPrice?: number | null;
  preSelected?: boolean;
  selected?: boolean;
  dayCoefficient?: number;
  monthCoefficient?: number;
  minDayPrice?: Nullable<number>;
  minMonthPrice?: Nullable<number>;
  showSurplusPercentage?: boolean;
  offerItemId?: number;
  productListItems: ProductListItem[];
  amount?: number;
}
export type PricingSheetRowKey = keyof PricingSheetRow;

export type OfferItem = PricingSheetRow & {
  approvalApproverComment?: Nullable<string>;
  itemId?: number;
  contractDayPrice?: Nullable<number>;
  contractMonthPrice?: Nullable<number>;
  includeRamiturva?: boolean;
  ramiturvaDayPrice?: number | null;
  ramiturvaMonthPrice?: number | null;
  ramiturvaProposalDayPrice?: number | null;
  ramiturvaProposalMonthPrice?: number | null;
  ramiturvaName?: string | null;
};

export interface TableRow extends PricingSheetRow {
  format?: string;
  children?: TableRow[];
  isExpanded?: boolean;
}

export interface PricingSheetItem {
  id: number;
  itemId: number;
  pricingItemId: number;
  pricingSheetId: number;
  dayPrice: number;
  monthPrice: number;
  proposalDayPrice?: number;
  proposalMonthPrice?: number;
  showByOfferText: boolean;
  approverComment?: string | null;
  creatorComment?: string | null;
}

export type RamiturvaPricesMira = Record<
  string,
  { ramiturvaDayPrice: number; ramiturvaMonthPrice: number }
>;

export type RamiturvaNames = Record<string, { ramiturvaName: string }>;

export interface PricingSheetItemResponse {
  itemId: number;
  pricingItemId: number;
  pricingSheetId: number;
  dayPrice: Nullable<string>;
  monthPrice: Nullable<string>;
  proposalDayPrice: number | null;
  proposalMonthPrice: number | null;
  dayPriceTwoDecimal: number | null;
  monthPriceTwoDecimal: number | null;
  proposalDayPriceTwoDecimal: number | null;
  proposalMonthPriceTwoDecimal: number | null;
  showByOfferText: boolean;
  approverComment: string | null;
  creatorComment: string | null;
  item: PricingSheetRow;
}

export interface PostPricingSheetItems {
  pricingSheetId: string;
  itemId: number;
  dayPrice: number;
  monthPrice: number;
  proposalDayPrice: number | null;
  proposalMonthPrice: number | null;
  showByOfferText: boolean;
  creatorComment: string;
  approverComment: string;
}

export interface ExternalPricingSheetItem {
  dayPrice: number | null;
  from: Date;
  item: { catClass: string };
  monthPrice: number | null;
  to: Date;
}

export enum ItemType {
  Unit = "UNIT",
  Bulk = "BULK",
  Kit = "KIT",
  Rental = "RENTAL",
  Sales = "SALES",
  Service = "SERVICE",
  Ramiturva = "RAMITURVA",
}

export enum CustomerType {
  MIRA = "1",
  PROSPECT = "2",
  PRIVATE = "3",
}

export enum TableType {
  PreSelectTable = "preselect",
  EditTable = "edit",
  InspectTable = "inspect",
  AdminTable = "admin",
  OfferPricingTable = "offerPricingTable",
  OfferItemDetails = "offerItemDetails",
  ProductLists = "productLists",
}

export enum AgGridColumnView {
  ADMIN_DAY = "ADMIN_DAY",
  ADMIN_MONTH = "ADMIN_MONTH",
  ADMIN_COMMON = "ADMIN_COMMON",
  ADMIN_BOTH = "ADMIN_BOTH",
  ADMIN_HIERARCHY = "ADMIN_HIERARCHY",
  ADMIN_INDUSTRY_DAY = "ADMIN_INDUSTRY_DAY",
  ADMIN_INDUSTRY_MONTH = "ADMIN_INDUSTRY_MONTH",
  ADMIN_KIT_NAME_LIST = "ADMIN_KIT_NAME_LIST",
  PRICING_DAY = "PRICING_DAY",
  PRICING_MONTH = "PRICING_MONTH",
  PRICING_BOTH = "PRICING_BOTH",
  PRESELECT = "PRESELECT",
  INSPECT_DAY = "INSPECT_DAY",
  INSPECT_BOTH = "INSPECT_BOTH",
  OFFER_PRESELECT = "OFFER_PRESELECT",
  OFFER_ITEMDETAILS = "OFFER_ITEMDETAILS",
  OFFER_PRICING = "OFFER_PRICING",
  OFFER_INSPECT = "OFFER_INSPECT",
  CRITICAL_EQUIPMENT = "CRITICAL_EQUIPMENT",
  SURPLUS_EQUIPMENT = "SURPLUS_EQUIPMENT",
  PRODUCT_LISTS = "PRODUCT_LISTS",
}

export enum PricingSheetState {
  Draft = "draft",
  OkDraft = "okDraft",
  WarningDraft = "warningDraft",
  Pending = "pending", // waiting for approval
  Approved = "approved",
  Ready = "ready", // ready for mira
  InUse = "inUse", // exported to mira
}

export enum TableSortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum PreSelectionType {
  COMPACT = "COMPACT",
  LARGE = "LARGE",
  EMPTY = "EMPTY",
}

export enum PdfQuality {
  LOW = "low",
  MED = "med",
  HIGH = "high",
}

export enum SheetType {
  OFFER = "offer",
  PRICING = "pricing",
}

interface MiraInfo {
  miraId: string;
  customerIds: string[];
}

export type PricingSheetRevision = Pick<PricingSheet, "id" | "state">;

export interface PricingSheet {
  id: string;
  customerName?: string;
  pdfCustomerName?: string;
  customerId?: string;
  name: string;
  userId: string;
  userEmail: string;
  state: PricingSheetState;
  level: string;
  createdAt?: Date;
  updatedAt?: Date;
  from?: Date;
  to?: Date;
  industry?: IndustryName;
  revenue?: number;
  potentialBottom: number;
  potentialTop: number;
  commitment?: number;
  site?: string;
  region?: string;
  nationWide?: boolean;
  regionallySignificant?: boolean;
  strategicallySignificant?: boolean;
  paymentTermDays?: number;
  creatorComment?: string;
  approverComment?: string;
  isLatestVersion?: boolean;
  expired?: boolean;
  miraInfo?: MiraInfo;
  pricingBasis?: PricingBasis;
  roundingBasisSheet?: RoundingBasis;
  roundingBasisPdf?: RoundingBasis;
  archivedAt?: Date;
  editingRights?: string;
  editorFullName?: string;
  editedByUser?: string;
  editingOpenTimestamp?: string;
  isSharedSheetLocked?: boolean;
  sellerName?: string;
  sellerEmail?: string;
  sellerPhoneNumber?: string;
  revision: PricingSheetRevision;
  original: PricingSheetRevision;
  approvalRequests?: ApprovalRequest[];
}

export type PricingSheetPost = Omit<
  PricingSheet,
  | "id"
  | "userId"
  | "userEmail"
  | "createdAt"
  | "updatedAt"
  | "archivedAt"
  | "revision"
  | "original"
>;

export type PricingSheetPut = Partial<PricingSheet>;

export type UserRole = "Pricing Admin" | "Pricing User";

export type RentalPotential = { bottom: number; top: number };

export interface BackgroundInfo {
  customerName?: string;
  pdfCustomerName?: string;
  industry?: string;
  region?: string;
  priceSheetStartDate: Date;
  priceSheetEndDate: Date;
  pricingBasis: PricingBasis;
  potential: RentalPotential;
  revenue: number;
  commitment: number;
  paymentTermDays: number;
  nationWide: boolean;
  regionallySignificant: boolean;
  strategicallySignificant: boolean;
  priceSheetType?: string;
  revenueOverMillion: boolean;
  potentialOver100k: boolean;
  name?: string;
  state: PricingSheetState;
  userId?: string;
}

export enum PriceCategory {
  PriceCategory1,
  PriceCategory2,
  PriceCategory3,
  PriceCategory4,
  PriceCategory5,
  PriceCategory6,
  PriceCategory7,
}

export enum ApprovalRequestState {
  Waiting = "waiting",
  Discarded = "discarded",
  Approved = "approved",
  Locked = "locked",
}

export enum IndustryName {
  CONSTRUCTION = "Rakentaminen",
  MANUFACTURING = "Teollisuus",
  HVAC = "Talotekniikka",
  INFRA = "Infra",
  EVENT = "Tapahtuma",
  OTHER = "Muu",
}

export interface ApprovalRequest {
  id: number;
  pricingSheetId: number;
  state: ApprovalRequestState;
  pricingSheet: PricingSheet;
  creatorUserEmail: string;
  approverUserEmail: string;
  createdAt: Date;
  updatedAt: Date;
  lockedReason: Nullable<string>;
  lockedBy: Nullable<string>;
}

export interface ItemGroup {
  name: string;
  value: string;
}

export type GridColumn<Basis extends PricingBasis | string = string> = {
  key: CoefficientType<Basis>;
  industryPercentKey: PercentCoefficientType<Basis>;
  name: string;
};

export type CoefficientColumn<Basis extends PricingBasis = "BOTH"> =
  GridColumn<Basis>;

export type DayPriceCoefficientType =
  | "dayPriceCoefficient1"
  | "dayPriceCoefficient2"
  | "dayPriceCoefficient3"
  | "dayPriceCoefficient4"
  | "dayPriceCoefficient5"
  | "dayPriceCoefficient6"
  | "dayPriceCoefficient7"
  | "dayPriceCoefficientList";

export type MonthPriceCoefficientType =
  | "monthPriceCoefficient1"
  | "monthPriceCoefficient2"
  | "monthPriceCoefficient3"
  | "monthPriceCoefficient4"
  | "monthPriceCoefficient5"
  | "monthPriceCoefficient6"
  | "monthPriceCoefficient7"
  | "monthPriceCoefficientList";

export type CoefficientType<Basis extends PricingBasis | string = "BOTH"> =
  Basis extends "DAY"
    ? DayPriceCoefficientType
    : Basis extends "MONTH"
      ? MonthPriceCoefficientType
      : Basis extends "BOTH"
        ? DayPriceCoefficientType | MonthPriceCoefficientType
        : string;

export type DayPricePercentCoefficientType =
  | "dayPricePercent1"
  | "dayPricePercent2"
  | "dayPricePercent3"
  | "dayPricePercent4"
  | "dayPricePercent5"
  | "dayPricePercent6"
  | "dayPricePercent7"
  | "dayPricePercentList";

export type MonthPricePercentCoefficientType =
  | "monthPricePercent1"
  | "monthPricePercent2"
  | "monthPricePercent3"
  | "monthPricePercent4"
  | "monthPricePercent5"
  | "monthPricePercent6"
  | "monthPricePercent7"
  | "monthPricePercentList";

export type PercentCoefficientType<
  Basis extends PricingBasis | string = "BOTH",
> = Basis extends "DAY"
  ? DayPricePercentCoefficientType
  : Basis extends "MONTH"
    ? MonthPricePercentCoefficientType
    : Basis extends "BOTH"
      ? DayPricePercentCoefficientType | MonthPricePercentCoefficientType
      : string;

export type CoefficientChangeType = `${CoefficientType}Change`;
export type PercentCoefficientChangeType = `${PercentCoefficientType}Change`;

export type CellType =
  | CoefficientType
  | PercentCoefficientType
  | "dayPrice"
  | "monthPrice"
  | "comparisonDayPrice"
  | "comparisonMonthPrice"
  | "comparisonMonthPricePercent"
  | "comparisonDayPricePercent"
  | "proposalDayPrice"
  | "proposalMonthPrice"
  | "discountDay"
  | "discountMonth"
  | "priceThreshold"
  | "rentalReadyPrice"
  | "targetUtilRate"
  | "depreciationPeriod"
  | "pimProductGroup"
  | "pimProductLine"
  | "pimCategory"
  | "quantity"
  | "pricingBasis"
  | "leasePeriodStart"
  | "leasePeriodEnd"
  | "comment"
  | "approvalApproverComment"
  | "removalPriceDay"
  | "removalPriceMonth"
  | "kitName"
  | "avgRentalPeriod"
  | "unit"
  | "ramiturvaPrice";

export type Envs = { [key: string]: string };

export type DataGridRow = { row: { catClass: string } };

export type SelectSheetOption = {
  id: number;
  value: string;
  label: string;
};

type AutomaticAuditEventType = "automaticPimUpdater" | "automaticItemRemoval";

export type AutomaticAuditEventKeyMap = {
  [K in AutomaticAuditEventType]: K;
};

export type AuditEventType =
  | CoefficientChangeType
  | PercentCoefficientChangeType
  | "dayPriceChange"
  | "monthPriceChange"
  | "rentalReadyChange"
  | "priceSheetCreate"
  | "priceSheetParamsChange"
  | "priceSheetStateChange"
  | "itemNameChange"
  | "targetUtilRateChange"
  | "rentalReadyPriceChange"
  | "includeInPricingChange"
  | "markedAsRemovedChange"
  | "isRamigreenChange"
  | "depreciationPeriodChange"
  | "pimProductGroupChange"
  | "pimProductLineChange"
  | "pimCategoryChange"
  | "pimPathChange"
  | "belongsToCompactPreSelectionChange"
  | "belongsToLargePreSelectionChange"
  | "pricingSheetArchived"
  | "pricingSheetRestored"
  | "pricingSheetRemoved"
  | AutomaticAuditEventType;

export type Nullable<T> = T | null | undefined;

export type NodeSummary = {
  id: number;
  displayName: string;
  displayDescription: string;
  version: string;
  createdBy: string;
  createdDate: string;
  formattedCreatedDate: string;
  modifiedBy: string;
  modifiedDate: string;
  formattedModifiedDate: string;
  resourceUrl: string | null;
  entityTypeId: string;
  entityTypeDisplayName: string;
  completeness: string | null;
};

export type NodeFieldValue = {
  fieldTypeId: string;
  value: any;
};

export type NodeItem = {
  entityId: number;
  summary: NodeSummary;
  fieldValues: NodeFieldValue[];
};

export type DateRange = {
  start: Date;
  end: Date;
};

export type RentalItemFieldValue = {
  fieldTypeId:
    | "RentalItemDisplayName"
    | "RentalItemRentalProduct"
    | "RentalItemIsBulk"
    | "RentalItemApprovedForFI"
    | "RentalItemChannelNodeWebFinland";
  value: any;
};

export type RentalItem = {
  entityId: number;
  fieldValues: RentalItemFieldValue[];
  inbound: any[];
};

export type Coefficients = {
  dayCoefficient: CoefficientType;
  monthCoefficient: CoefficientType;
  dayPricePercent: string;
  monthPricePercent: string;
};

export type ApprovalReasonsType = {
  belowOneCoefficient: {
    count: number;
    requireApproval: boolean;
  };
  // orange rows
  below1Point2Coefficient: {
    count: number;
    requireApproval: boolean;
  };
  // pink rows
  below70PercentOfPropositionPrice: {
    count: number;
    requireApproval: boolean;
  };
  paymentTerms: {
    requireApproval: boolean;
  };
  sheetPeriod: {
    requireApproval: boolean;
  };
  valueAbove500: {
    requireApproval: boolean;
  };
  valueAbove200: {
    requireApproval: boolean;
  };
};

/**
 * CRITICAL EQUIPMENT
 */

export type CriticalEquipmentItem = {
  from: string;
  id?: number;
  itemId: number;
  minPriceDay: string | number;
  minPriceMonth: string | number;
  to: string;
};

export type CriticalEquipmentRowState = {
  minPriceDay: string | number;
  minPriceMonth: string | number;
  from: Date | string | null;
  to: Date | string | null;
};

export type CriticalEquipmentRow = Omit<CriticalEquipmentItem, "id" | "itemId">;

export type CriticalEquipmentDateKey = "from" | "to";
export type CriticalEquipmentPriceKey = "minPriceDay" | "minPriceMonth";
export type CriticalEquipmentRowKeys = keyof CriticalEquipmentRow;

/**
 * SURPLUS EQUIPMENT
 */

export type SurplusEquipmentItem = {
  surplusFrom: string;
  id?: number;
  itemId: number;
  changePercentage?: number | string;
  surplusTo: string | null;
};

export type SurplusEquipmentRow = Omit<SurplusEquipmentItem, "id" | "itemId">;

export type SurplusEquipmentRowKeys = keyof SurplusEquipmentRow;

// INDUSTRY PERCENT

export type ItemIndustryPercent = PricingSheetRow &
  Record<PercentCoefficientType, number | string> & {
    industry: {
      id: number;
      name: IndustryName;
    };
    industryId: number;
    itemId: number;
  };

export type NewItemIndustryPercent = Omit<ItemIndustryPercent, "id">;
