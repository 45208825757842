import { EditableCallbackParams } from 'ag-grid-community';
import { GridColumnProps } from '../Columns/ColumnsBase';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

const ProductListColumns = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps) => {
  return [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'amount',
      headerName: 'Määrä',
      editable: (event: EditableCallbackParams) => {
        return event.node.isSelected();
      },
      onCellValueChanged: () => {
        optionalColumnProps.handleUpdateRowValue();
      },
      cellEditor: 'agNumberCellEditor',
      cellStyle: {
        borderLeft: '1px solid #ccc',
      },
    },
  ];
};

export default ProductListColumns;
