import { Tooltip, TooltipProps, Typography } from '@mui/material';

export type DefaultTooltipProps = TooltipProps & {
  hide?: boolean;
  maxHeight?: number;
  overflow?: string;
  backgroundColor?: string;
};
const DefaultTooltip = ({
  title,
  children,
  hide = false,
  maxHeight,
  overflow,
  backgroundColor,
  ...props
}: DefaultTooltipProps) => {
  if (!title) {
    hide = true;
  }
  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            display: hide ? 'none' : '',
            maxWidth: 650,
            overflow,
            maxHeight,
            backgroundColor,
          },
        },
      }}
      {...props}
      title={<Typography fontSize={14}>{title}</Typography>}
    >
      <Typography
        component={'span'}
        lineHeight={'inherit'}
        fontSize={'inherit'}
        fontWeight={'inherit'}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
export default DefaultTooltip;
