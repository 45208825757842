import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import CommitmentSlider from './Sliders/CommitmentSlider';

import { BackgroundInfo } from '../../../../../shared/types';

import { formatRentalPotential } from '../InitialInformationStep';
import { initCommitment } from '../../../providers/Background/BackgroundProvider';

import DefaultTooltip from '../../Tooltips/DefaultTooltip';

const commitmentToolTipText =
  'Kuinka monta prosenttia koko vuokrapotentiaalista kohdistuu Ramirentiin?';

interface CommitmentProps {
  backgroundInfo: BackgroundInfo;
  changeValuesEnabled: boolean;
}
const Commitment: React.FC<CommitmentProps> = ({
  backgroundInfo,
  changeValuesEnabled,
}) => {
  return (
    <>
      <DefaultTooltip title={commitmentToolTipText} placement="top">
        <Box sx={{ mr: 2, mt: 2 }} minWidth={380}>
          <Typography color="primary">
            Asiakkaan sitoutuminen Ramirentiin*
          </Typography>
          <CommitmentSlider
            initial={initCommitment}
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
      </DefaultTooltip>
      <Box sx={{ mr: 2 }} minWidth={380}>
        <Box sx={{ mt: 0 }}>
          <Typography color="primary">
            Lopullinen vuokrapotentiaali
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" color="text.primary">
              {formatRentalPotential(
                backgroundInfo?.potential?.bottom,
                backgroundInfo?.potential?.top,
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Commitment;
