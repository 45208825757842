import { MenuItem, Select } from '@mui/material';

interface DealProbabilitySelectorProps {
  value: number | undefined;
  onDealProbabilityChange: (value: string) => void;
}

const menuItems = [
  {
    value: 0,
    scale: { min: 0, max: 4 },
    label: 'Ei toteudu  (0%)',
  },
  {
    value: 10,
    scale: { min: 5, max: 19 },
    label: 'Erittäin epätodennäköinen (10%)',
  },
  {
    value: 30,
    scale: { min: 20, max: 39 },
    label: 'Epätodennäköinen (30%)',
  },
  {
    value: 50,
    scale: { min: 40, max: 57 },
    label: '50-50 (50%)',
  },
  {
    value: 65,
    scale: { min: 58, max: 72 },
    label: 'Todennäköinen (65%)',
  },
  {
    value: 80,
    scale: { min: 73, max: 89 },
    label: 'Erittäin todennäköinen (80%)',
  },
  {
    value: 100,
    scale: { min: 90, max: 100 },
    label: 'Varma (100%)',
  },
];

const DealProbabilitySelector: React.FC<
  DealProbabilitySelectorProps
> = ({ value, onDealProbabilityChange }) => {
  const findValue = (value: number | undefined): string => {
    if (value === undefined || value === null) return '';

    const menuValue = menuItems.find(
      ({ scale: { min, max } }) => value >= min && value <= max,
    );

    return menuValue ? String(menuValue.value) : '';
  };

  return (
    <Select
      value={findValue(value)}
      onChange={(event) => {
        onDealProbabilityChange(event.target.value);
      }}
      size="small"
      sx={{
        width: '100%',
        my: 1,
      }}
    >
      {menuItems.map((menuItem) => {
        return (
          <MenuItem key={menuItem.value} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default DealProbabilitySelector;
