import { useState, useContext, useEffect } from 'react';

import { useApi, LoadingState } from '../../../hooks/useApi';

import { useHistory } from 'react-router-dom';

import {
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
  Alert,
  Button,
  AlertTitle,
} from '@mui/material';
import { formatDateTime } from '../../../utils/formatDateTimes';
import useUserContext from '../../../providers/User/UserProvider';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import GenericCheckBoxFilter from '../../../components/Filters/GenericCheckBoxFilter';
import {
  ApprovalRequest,
  ApprovalRequestState,
} from '../../../../../shared/types';
import { getApprovalRequestStateLabel } from '../../../../../shared/helpers/getApprovalRequestStateLabel';

export const formatReason = (reason: string) => {
  switch (reason) {
    case 'approved':
      return 'hyväksyi';
    case 'discarded':
      return 'hylkäsi';
    case 'archived':
      return 'arkistoi';
    default:
      return '???';
  }
};

const PricingApproval = () => {
  const [
    approvalRequests,
    isLoadingApprovalRequests,
    approvalRequestsError,
  ] = useApi<ApprovalRequest[]>('/approvalrequests');

  let history = useHistory();

  const { userEmail } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);

  const [showOnlyOwn, setShowOnlyOwn] = useState(false);

  const filteredApprovalRequests =
    showOnlyOwn && approvalRequests
      ? approvalRequests.filter(
          (row) => row.approverUserEmail === userEmail,
        )
      : approvalRequests;

  useEffect(() => {
    if (approvalRequestsError) {
      setNotification({
        severity: 'error',
        message: 'Hyväksyntäpyyntöjen haku epäonnistui!',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalRequestsError]);

  const goToInspectPricingSheet = (
    id: number,
    approvalId: number,
  ) => {
    history.push(
      `/tarkastele-hinnastoa/${id}/hyvaksynta/${approvalId}`,
    );
  };

  return (
    <>
      {isLoadingApprovalRequests === LoadingState.IsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <GenericCheckBoxFilter
            label="Näytä minulta hyväksyntää vaativat"
            checked={showOnlyOwn}
            handleChecked={setShowOnlyOwn}
          />

          <Alert severity="info">
            Näytetään viimeiset 1000 pyyntöä.
          </Alert>

          <TableContainer>
            <Table
              style={{ minWidth: 650 }}
              aria-label="approval-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Pyyntö luotu</TableCell>
                  <TableCell>Hinnasto</TableCell>

                  <TableCell></TableCell>
                  <TableCell>Pyytäjä</TableCell>
                  <TableCell>Hyväksyntää pyydetty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredApprovalRequests?.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {formatDateTime(row.createdAt)}
                    </TableCell>
                    <TableCell>
                      {row?.pricingSheet?.name ?? '-'}
                    </TableCell>
                    <TableCell component="th">
                      {row.state !== ApprovalRequestState.Waiting ? (
                        <>
                          {row.state ===
                            ApprovalRequestState.Approved && (
                            <Alert severity="success">
                              <AlertTitle>
                                {`${getApprovalRequestStateLabel(
                                  row.state,
                                )} `}
                                {formatDateTime(row.updatedAt)}
                              </AlertTitle>
                            </Alert>
                          )}
                          {row.state ===
                            ApprovalRequestState.Discarded && (
                            <Alert severity="error">
                              <AlertTitle>
                                {`${getApprovalRequestStateLabel(
                                  row.state,
                                )} `}
                                {formatDateTime(row.updatedAt)}
                              </AlertTitle>
                            </Alert>
                          )}
                          {row.state ===
                            ApprovalRequestState.Locked && (
                            <Alert severity="info">
                              <AlertTitle>
                                {`${getApprovalRequestStateLabel(
                                  row.state,
                                )} `}
                                {formatDateTime(row.updatedAt)}
                              </AlertTitle>
                              {row.lockedBy}{' '}
                              {formatReason(row.lockedReason ?? '')}{' '}
                              hinnaston
                            </Alert>
                          )}
                        </>
                      ) : (
                        <Button
                          disabled={
                            row.approverUserEmail === userEmail
                              ? false
                              : true
                          }
                          variant="contained"
                          onClick={() =>
                            goToInspectPricingSheet(
                              row.pricingSheetId,
                              row.id,
                            )
                          }
                        >
                          Tarkastele hinnastoa
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>{row.creatorUserEmail}</TableCell>
                    <TableCell>{row.approverUserEmail}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default PricingApproval;
