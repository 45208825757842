import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { BackgroundInfo } from '../../../../../shared/types';

interface HeaderProps {
  backgroundInfo: BackgroundInfo;
}
const Header: React.FC<HeaderProps> = ({ backgroundInfo }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{ mt: 1, mb: 0 }}
        variant="h5"
        component="div"
        gutterBottom
        color="primary"
      >
        Asiakkaan perustiedot
      </Typography>
      <Typography
        sx={{ ml: 1 }}
        variant="body1"
        color={'text.secondary'}
      >
        {backgroundInfo?.name ? `${backgroundInfo?.name}` : null}
      </Typography>
    </Box>
  );
};

export default Header;
