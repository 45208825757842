import {
  DatabaseOfferApprovalRequestWithOffer,
  DatabaseOfferItem,
  OfferState,
  UpdatedDatabaseOfferItem,
} from '../../../../shared/types/offers';
import {
  AgGridColumnView,
  Nullable,
  OfferItem,
  PricingBasis,
  RowFieldType,
} from '../../../../shared/types';
import { GroupingType } from '../../hooks/useColumns';
import {
  CellClassParams,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { getGroupFilterForGroup } from '../../helpers/groupFilter';
import calculateMeansByGroup, {
  GroupingParams,
} from '../../utils/calculateMeansByGroup';
import WarningCounts from '../../components/Cells/common/WarningCounts';
import CoefficientChips from '../../components/Cells/common/CoefficientChips';
import calculateWarningCounts from '../../utils/calculateWarningCounts';
import {
  DoNotDisturb,
  EditOutlined,
  ForwardToInboxOutlined,
  EventBusy,
  PendingOutlined,
  TaskAlt,
} from '@mui/icons-material';
import CommentIndicator from '../../components/Common/CommentIndicator';
import CommentToolTip from '../../components/Tooltips/CommentToolTip';
import Typography from '@mui/material/Typography';
import { AlertColor } from '@mui/material';
import styled from '@emotion/styled';
import { MIRA_PRODUCT_GROUP } from '../../../../shared/constants';

export const ROUNDING_BASIS = 'TWODECIMALS';

export const getOfferStateLabel = (state: Nullable<OfferState>) => {
  switch (state) {
    case OfferState.Draft:
      return 'Luonnos';
    case OfferState.InternalApproval:
      return 'Sis. hyväksyntä';
    case OfferState.InternalRejection:
      return 'Palautettu';
    case OfferState.ReadyForSending:
      return 'Valmis lähetettäväksi';
    case OfferState.Sent:
      return 'Lähetetty';
    case OfferState.Approved:
      return 'Hyväksytty';
    case OfferState.Rejected:
      return 'Hylätty';
    case OfferState.RequiresChanges:
      return 'Muokattava';
    case OfferState.Expired:
      return 'Vanhentunut';
    case OfferState.AwaitingOrder:
      return 'Odottaa tilausta';
    case OfferState.PartiallyOrdered:
      return 'Osittain tilattu';
    case OfferState.Ordered:
      return 'Tilattu';
    default:
      return '';
  }
};

export const getOfferStateIcon = (state: OfferState) => {
  switch (state) {
    case OfferState.Draft:
      return EditOutlined;
    case OfferState.InternalApproval:
      return PendingOutlined;
    case OfferState.InternalRejection:
    case OfferState.Rejected:
      return DoNotDisturb;
    case OfferState.ReadyForSending:
    case OfferState.Approved:
      return TaskAlt;
    case OfferState.Sent:
      return ForwardToInboxOutlined;
    case OfferState.RequiresChanges:
      return EditOutlined;
    case OfferState.Expired:
      return EventBusy;
    case OfferState.AwaitingOrder:
      return EditOutlined;
    case OfferState.PartiallyOrdered:
      return EditOutlined;
    case OfferState.Ordered:
      return EditOutlined;
  }
};

export const getOfferStateSeverity = (
  state: OfferState,
): AlertColor => {
  switch (state) {
    case OfferState.ReadyForSending:
    case OfferState.Approved:
    case OfferState.Ordered:
      return 'success';
    case OfferState.InternalApproval:
    case OfferState.RequiresChanges:
    case OfferState.Expired:
      return 'warning';
    case OfferState.InternalRejection:
    case OfferState.Rejected:
      return 'error';
    default:
      return 'info';
  }
};

export const getUpdatedOfferItem = ({
  comment,
  dayPrice,
  leasePeriodEnd,
  leasePeriodStart,
  monthPrice,
  pricingBasis,
  proposalDayPrice,
  proposalMonthPrice,
  quantity,
  unit,
}: OfferItem): UpdatedDatabaseOfferItem => ({
  comment: comment || null,
  dayPrice,
  leasePeriodEnd,
  leasePeriodStart,
  monthPrice,
  pricingBasis,
  proposalDayPrice,
  proposalMonthPrice,
  quantity,
  unit,
});

export const getUpdatedOfferItems = (
  offerItems: DatabaseOfferItem[] = [],
  pricingItems: OfferItem[],
): DatabaseOfferItem[] => {
  type UpdatedOfferItemMapping = Record<
    string,
    Pick<
      OfferItem,
      keyof Omit<
        UpdatedDatabaseOfferItem,
        | 'id'
        | 'offerSheetId'
        | 'itemId'
        | 'details'
        | 'dayPriceCoefficient'
        | 'proposalDayPriceCoefficient'
        | 'offerDayPriceCoefficient'
        | 'offerProposalDayPriceCoefficient'
        | 'monthPriceCoefficient'
        | 'proposalMonthPriceCoefficient'
        | 'offerMonthPriceCoefficient'
        | 'offerProposalMonthPriceCoefficient'
      >
    >
  >;
  // create a itemId - item value mapping of the offer items
  const pricesForId =
    pricingItems.reduce((acc, item) => {
      if (!item.offerItemId) {
        return acc;
      }
      return {
        ...acc,
        [item.offerItemId]: getUpdatedOfferItem(item),
      };
    }, {} as UpdatedOfferItemMapping) || {};
  return offerItems.map((item) => ({
    ...item,
    ...(item.id in pricesForId && pricesForId[item.id]),
  }));
};

type GetWarningsAndCoefficientsFnParams = {
  filteredRows: OfferItem[];
  gridColumnView: AgGridColumnView;
  groupingStyle?: GroupingType;
  offerSheetItems: OfferItem[];
  pricingBasis: PricingBasis;
  row?: ICellRendererParams<OfferItem>;
};
export const getWarningsAndCoefficientsForRenderer = ({
  filteredRows,
  gridColumnView,
  groupingStyle,
  offerSheetItems,
  pricingBasis,
  row,
}: GetWarningsAndCoefficientsFnParams) => {
  const {
    dayWarnings,
    dayLightWarnings,
    dayBelow70Warnings,
    dayZeroPriceWarnings,
    monthWarnings,
    monthLightWarnings,
    monthBelow70Warnings,
    monthZeroPriceWarnings,
    dayBelowCriticalWarnings,
    monthBelowCriticalWarnings,
    invalidPriceThresholdRows,
  } = calculateWarningCounts(offerSheetItems, 'BOTH');

  let groupingParams: GroupingParams | undefined = undefined;

  if (row && groupingStyle) {
    const field = row.node.field as RowFieldType;
    const value = row.value;
    groupingParams = {
      field,
      value,
      groupingStyle,
    };
  }

  const groupFilter = groupingParams
    ? getGroupFilterForGroup(
        groupingParams.field,
        groupingParams.value,
        groupingParams.groupingStyle,
      )
    : null;

  const getWarningCount = (item: OfferItem[]) =>
    groupFilter ? item.filter(groupFilter).length : item.length;

  const {
    meanFilledCoefficientOfAllDayRows,
    meanProposalCoefficientOfAllDayRows,
    meanFilledCoefficientOfAllMonthRows,
    meanProposalCoefficientOfAllMonthRows,
  } = calculateMeansByGroup({
    rows: filteredRows,
    groupingParams,
  });
  return (
    <>
      <WarningCounts
        countDayPriceWarnings={getWarningCount(dayWarnings)}
        countMonthPriceWarnings={getWarningCount(monthWarnings)}
        countDayPriceLightWarnings={getWarningCount(dayLightWarnings)}
        countMonthPriceLightWarnings={getWarningCount(
          monthLightWarnings,
        )}
        countBelow70PercentDayPriceRows={getWarningCount(
          dayBelow70Warnings,
        )}
        countBelow70PercentMonthPriceRows={getWarningCount(
          monthBelow70Warnings,
        )}
        countZeroDayPriceRows={getWarningCount(dayZeroPriceWarnings)}
        countZeroMonthPriceRows={getWarningCount(
          monthZeroPriceWarnings,
        )}
        countCriticalDayPriceWarnings={getWarningCount(
          dayBelowCriticalWarnings,
        )}
        countCriticalMonthPriceWarnings={getWarningCount(
          monthBelowCriticalWarnings,
        )}
        countPriceThresholdWarnings={getWarningCount(
          invalidPriceThresholdRows,
        )}
        pricingBasis={pricingBasis}
      />
      <CoefficientChips
        means={{
          meanFilledDay: meanFilledCoefficientOfAllDayRows,
          meanProposalDay: meanProposalCoefficientOfAllDayRows,
          meanFilledMonth: meanFilledCoefficientOfAllMonthRows,
          meanProposalMonth: meanProposalCoefficientOfAllMonthRows,
        }}
        pricingBasis={pricingBasis}
        gridColumnView={gridColumnView}
      />
    </>
  );
};

export const catClassInnerRenderer = (row: OfferItem) => (
  <>
    {row.approvalApproverComment && (
      <CommentToolTip
        title={
          <>
            <Typography color={'text.primary'}>
              {row.approvalApproverComment}
            </Typography>
          </>
        }
      >
        <CommentIndicator />
      </CommentToolTip>
    )}
  </>
);

export const getWarningCounts = (offerSheetItems: OfferItem[]) => {
  const {
    dayWarnings,
    monthWarnings,
    dayLightWarnings,
    monthLightWarnings,
    dayBelow70Warnings,
    monthBelow70Warnings,
    dayZeroPriceWarnings,
    monthZeroPriceWarnings,
    dayBelowCriticalWarnings,
    monthBelowCriticalWarnings,
  } = calculateWarningCounts(offerSheetItems, 'BOTH');

  const calculateWarningCount = (
    dayWarnings: OfferItem[],
    monthWarning: OfferItem[],
  ) => {
    const basisFilter =
      (pricingBasis: PricingBasis) => (item: OfferItem) =>
        item.pricingBasis === pricingBasis;
    return (
      dayWarnings.filter(basisFilter('DAY')).length +
      monthWarning.filter(basisFilter('MONTH')).length
    );
  };

  return {
    get belowOneCoefficient() {
      return calculateWarningCount(dayWarnings, monthWarnings);
    },
    get below1Point2Coefficient() {
      return calculateWarningCount(
        dayLightWarnings,
        monthLightWarnings,
      );
    },
    get below70() {
      return calculateWarningCount(
        dayBelow70Warnings,
        monthBelow70Warnings,
      );
    },
    get zeroPrice() {
      return calculateWarningCount(
        dayZeroPriceWarnings,
        monthZeroPriceWarnings,
      );
    },
    get belowCritical() {
      return calculateWarningCount(
        dayBelowCriticalWarnings,
        monthBelowCriticalWarnings,
      );
    },
  };
};

export const getUsersAllowedToInspect = (
  item: DatabaseOfferApprovalRequestWithOffer | undefined,
): string[] => {
  if (!item) return [];
  const { approverEmail, previousRequests = [] } = item;
  const usersAllowedToInspect = previousRequests
    // combine approver email(s) from previous requests
    .map(({ approverEmail }) => approverEmail)
    // add approver email for the current approval request
    .concat(...approverEmail.split(', '));
  return [...new Set(usersAllowedToInspect)];
};

export const isMonthPricing = <
  T extends
    | CellClassParams
    | ICellEditorParams
    | ICellRendererParams
    | ValueFormatterParams
    | ValueGetterParams,
>(
  params: T,
) => {
  return params.data?.pricingBasis === 'MONTH';
};

export const NotificationText = styled(Typography)`
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
`;

export const composeMiraOfferItem = ({
  catClass,
  name,
  type,
  leasePeriodStart,
  leasePeriodEnd,
}: Pick<
  OfferItem,
  'catClass' | 'name' | 'type' | 'leasePeriodStart' | 'leasePeriodEnd'
>): OfferItem => ({
  id: -1,
  catClass,
  name,
  type,
  productGroup: MIRA_PRODUCT_GROUP,
  productLine: null,
  category: null,
  pimProductGroup: MIRA_PRODUCT_GROUP,
  criticalEquipmentItems: [],
  surplusEquipmentItem: null,
  includeInPricing: false,
  markedAsRemoved: false,
  quantity: 1,
  leasePeriodStart,
  leasePeriodEnd,
  pricingBasis: 'DAY',
  preSelected: true,
  unit: 'kpl',
  includeRamiturva: false,
  productListItems: [],
});
