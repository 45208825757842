import { AlertColor } from '@mui/material';
import { PricingSheetState } from '../../../../shared/types';
import {
  CheckCircleOutline,
  Done,
  ImportExport,
  Mode,
  Pending,
  QuestionMark,
} from '@mui/icons-material';

export const getPricingSheetStateSeverity = (
  state: PricingSheetState,
): AlertColor => {
  switch (state) {
    case PricingSheetState.InUse:
    case PricingSheetState.Approved:
      return 'success';
    case PricingSheetState.Draft:
    case PricingSheetState.OkDraft:
    case PricingSheetState.Pending:
    case PricingSheetState.Ready:
    case PricingSheetState.WarningDraft:
      return 'warning';
    default:
      return 'info';
  }
};

export const getSheetStateIcon = (state: PricingSheetState) => {
  switch (state) {
    case PricingSheetState.InUse:
      return CheckCircleOutline;
    case PricingSheetState.Approved:
      return Done;
    case PricingSheetState.Draft:
    case PricingSheetState.Pending:
      return Pending;
    case PricingSheetState.Ready:
      return ImportExport;
    case PricingSheetState.OkDraft:
    case PricingSheetState.WarningDraft:
      return Mode;
    default:
      return QuestionMark;
  }
};
