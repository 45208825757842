import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  DatabaseItem,
  PricingSheetRow,
} from '../../../../../shared/types';

type SimpleProductListViewProps = {
  items: DatabaseItem[];
  getItems?: () => PricingSheetRow[];
};
export const SimpleProductListView = ({
  items,
  getItems,
}: SimpleProductListViewProps) => {
  const tableItems = getItems ? getItems() : items;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {['CatClass', 'Nimi', 'Määrä'].map((cell) => (
              <TableCell key={cell} style={{ fontWeight: 'bold' }}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableItems
            .sort((a, b) => {
              return Number(a.catClass) - Number(b.catClass);
            })
            .map((item) => {
              return (
                <TableRow key={item.catClass}>
                  <TableCell>{item.catClass}</TableCell>
                  <TableCell align="left">
                    {item.pimDisplayName ?? item.name}
                  </TableCell>
                  <TableCell align="left">{item.amount}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
