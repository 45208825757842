import {
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useHistory } from 'react-router-dom';
import { login } from './services/user';

import useUserContext from './providers/User/UserProvider';
import NotificationProvider from './providers/Notification/NotificationProvider';
import { Link } from '@mui/material';
import { EnvContext } from './App';

const Login = () => {
  const {
    userEmail,
    setUserEmail,
    setUserId,
    setLoggedIn,
    setUserFirstName,
    setUserLastName,
    setUserPhoneNumber,
  } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);
  const envs = useContext(EnvContext);

  let history = useHistory();

  useEffect(() => {
    if (userEmail) {
      history.push('/index');
    }
  });

  const [email, setEmail] = useState<string | undefined>('');
  const [password, setPassword] = useState<string | undefined>('');

  const handleEmailChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
  }) => {
    try {
      event.preventDefault();
      if (email && password) {
        const loginResponse = await login(email, password);
        if (loginResponse?.status === 200) {
          if (loginResponse.data.user) {
            const user = JSON.parse(loginResponse.data.user);
            setUserEmail(user.email);
            setUserId(user.id);
            setUserFirstName(user.first_name);
            setUserLastName(user.last_name);
            setUserPhoneNumber(user.phone_number);
            setLoggedIn(true);
            history.push('/index');
          }
        } else {
          setNotification({
            type: 'SNACKBAR',
            duration: 4000,
            severity: 'error',
            message: 'Kirjautuminen epäonnistui!',
          });
        }
      } else if (!email) {
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'warning',
          message: 'Sähköpostiosoite puuttuu!',
        });
      } else if (!password) {
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'warning',
          message: 'Salasana puuttuu!',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: 'white',
          borderRadius: 2,
          paddingTop: 4,
          paddingBottom: 2,
          marginTop: 8,
        }}
      >
        <Typography
          sx={{ m: 2 }}
          variant="h4"
          component="div"
          gutterBottom
          color="primary"
        >
          Kirjaudu sisään
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Sähköpostiosoite"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
          >
            Kirjaudu sisään
          </Button>
          <Link
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            href={envs?.VITE_AUTH_URL + '/public/recover?pricingtool'}
            target="_blank"
            rel="noreferrer"
          >
            Unohdin salasanani
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default Login;
