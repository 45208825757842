import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import GenericCheckbox from './Checkboxes/GenericCheckbox';
import RentalPotentialForm from './Forms/RentalPotentialForm';

import PotentialSlider from './Sliders/PotentialSlider';
import { initPotentialTop } from '../../../providers/Background/BackgroundProvider';

interface PotentialProps {
  changeValuesEnabled: boolean;
}
const Potential: React.FC<PotentialProps> = ({
  changeValuesEnabled,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <GenericCheckbox
          label={'Vuokrapotentiaali yli 100 000€'}
          propName={'potentialOver100k'}
          changeValuesEnabled={changeValuesEnabled}
        />
      </Box>
      <Typography color="text.primary">Vuokrapotentiaali</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '20%' }}>
          <RentalPotentialForm
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
        <Box sx={{ width: '80%' }}>
          <PotentialSlider
            initial={initPotentialTop}
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
      </Box>
    </>
  );
};

export default Potential;
