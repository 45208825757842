import { createContext, useCallback, useMemo, useState } from 'react';

import {
  BackgroundInfo,
  PricingBasis,
  RoundingBasis,
  PricingSheetState,
} from '../../../../shared/types';
import useUserContext from '../User/UserProvider';

const Background = createContext<any | null>(null);

export const initPotentialBottom = 4;
export const initPotentialTop = 8;
export const initRevenue = 30;
export const initCommitment =
  (initPotentialBottom / initPotentialTop) * 100;

const BackgroundProvider = (props: any) => {
  const { userEmail, userFirstName, userLastName, userPhoneNumber } =
    useUserContext();

  const defaultBackgroundData = useMemo(
    () => ({
      customerName: undefined,
      pdfCustomerName: undefined,
      industry: undefined,
      region: undefined,
      priceSheetStartDate: new Date(),
      priceSheetEndDate: new Date(),
      pricingBasis: 'BOTH' as PricingBasis,
      roundingBasisSheet: 'TWODECIMALS' as RoundingBasis,
      roundingBasisPdf: 'TWODECIMALS' as RoundingBasis,
      potential: {
        bottom: initPotentialBottom,
        top: initPotentialTop,
      },
      revenue: initRevenue,
      commitment: initCommitment,
      paymentTermDays: 14,
      nationWide: false,
      regionallySignificant: false,
      strategicallySignificant: false,
      priceSheetType: undefined,
      revenueOverMillion: false,
      potentialOver100k: false,
      state: PricingSheetState.OkDraft,
      sellerName: userFirstName + ' ' + userLastName,
      sellerEmail: userEmail,
      sellerPhoneNumber: userPhoneNumber,
    }),
    [userEmail, userFirstName, userLastName, userPhoneNumber],
  );

  const [currentBackgroundInfo, setCurrentBackgroundInfo] =
    useState<BackgroundInfo>(defaultBackgroundData);
  const [backgroundInfo, setBackgroundInfo] =
    useState<BackgroundInfo>(defaultBackgroundData);
  const [updateInitialInfoEnabled, setUpdateInitialInfoEnabled] =
    useState(false);

  const resetBackgroundInfo = useCallback(() => {
    setBackgroundInfo(defaultBackgroundData);
    setCurrentBackgroundInfo(defaultBackgroundData);
  }, [defaultBackgroundData]);

  const children = props.children;

  return (
    <Background.Provider
      value={{
        currentBackgroundInfo,
        setCurrentBackgroundInfo,
        backgroundInfo,
        setBackgroundInfo,
        updateInitialInfoEnabled,
        setUpdateInitialInfoEnabled,
        resetBackgroundInfo,
      }}
    >
      {children}
    </Background.Provider>
  );
};

export default Background;

export { BackgroundProvider };
