import { useContext, useEffect, useState } from 'react';
import { processEnvs } from './config/processEnvs';

import './App.css';

import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import {
  Link,
  Route,
  Switch,
  Prompt,
  Redirect,
  useLocation,
  useHistory,
  RouteProps,
} from 'react-router-dom';

import PrivateRoute from './components/Routes/PrivateRoute';

import ramirentLogo from './img/ramirent-logo.png';

import Login from './Login';
import NotFound from './NotFound';
import HorizontalNonLinearStepper from './pages/Stepper';
import PricingSheets from './pages/PricingSheets';

import Notification from './components/Notification/Notification';

import useUserContext from './providers/User/UserProvider';

import NotificationProvider from './providers/Notification/NotificationProvider';

import Admin from './pages/Admin/Admin';
import { BackgroundProvider } from './providers/Background/BackgroundProvider';
import { PricingProvider } from './providers/Pricing/PricingProvider';
import { RowsProvider } from './providers/Rows/RowsProvider';

import { ItemIndustryPercentsProvider } from './providers/Rows/ItemIndustryPercents/ItemIndustryPercentsProvider';

import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { Lock, Person } from '@mui/icons-material';
import { logout } from './services/user';

import { checkSessionStatus } from './services/user';
import React from 'react';
import { Envs, Nullable, SheetType } from '../../shared/types';
import { getEnvs } from './services/general';
import EditPricingSheet from './pages/EditPricingSheet';
import { getPricingSheetById } from './services/pricingSheets';

import { LicenseManager } from 'ag-grid-enterprise';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fi } from 'date-fns/locale';
import { isEditingRights } from '../../shared/helpers/isEditingRights';
import { isEditingLocked } from '../../shared/helpers/isEditingLocked';
import CreateOffer from './pages/OfferSheets/CreateOffer';
import EditOffer from './pages/OfferSheets/EditOffer';
import { OfferSheetsTable } from './pages/OfferSheets/OfferSheetsTable/OfferSheetsTable';
import { InspectOffer } from './pages/OfferSheets/InspectOffer';
import { OffersTableTabState } from '../../shared/types/offers';
import { ErrorFallback } from './ErrorFallback';
import InspectPricingSheetInit from './pages/Admin/InspectPricingSheetInit';

LicenseManager.setLicenseKey(
  'CompanyName=Ramirent Finland Oy,LicensedApplication=pricing-planner,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-039678,SupportServicesEnd=19_March_2024_[v2]_MTcxMDgwNjQwMDAwMA==aa4b35573987f40d9c654f82b3146f1d',
);

export const EnvContext = React.createContext<Envs | null>(null);

const getPathAlternatives = (obj: object): string => {
  return Object.values(obj).join('|');
};

const generateInitials = (
  firstName: Nullable<string>,
  lastName: Nullable<string>,
) => {
  let initials = '';
  if (firstName && firstName.length > 0) {
    initials += firstName[0].toUpperCase();
  }
  if (lastName && lastName.length > 0) {
    initials += lastName[0].toUpperCase();
  }
  return initials;
};

let intervalId: any = null;

const UserInformation: React.FC<{
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: Nullable<string>;
}> = ({ firstName, lastName, email }) => {
  return (
    <>
      <Avatar style={{ backgroundColor: '#003287' }}>
        {generateInitials(firstName, lastName)}
      </Avatar>
      <Box
        marginRight="auto"
        marginLeft={2}
        overflow="auto"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          color={'text.primary'}
        >{`${firstName} ${lastName}`}</Typography>
        <Typography color={'text.secondary'}>{`${email}`}</Typography>
      </Box>
    </>
  );
};

const isAllowedToViewOffers = (
  userEmail: Nullable<string>,
  allowedUsers: Nullable<string>,
  herokuPrNumber: Nullable<string>,
) => {
  if (processEnvs.NODE_ENV === 'development') {
    return true;
  }
  // Allow access on heroku review apps
  if (herokuPrNumber !== undefined) {
    return true;
  }

  if (allowedUsers === '*') {
    return true;
  }

  if (!userEmail || !allowedUsers) {
    return false;
  }

  return allowedUsers.split(',').includes(userEmail);
};

const isEditSheetPath = (path: string) =>
  path.includes('/muokkaa-hinnastoa/');

const isOfferSheetPath = (path: string) =>
  path.startsWith('/tarjous');

const PrivateOfferRoute = (props: RouteProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const { pathname } = useLocation();
  const { userEmail } = useUserContext();
  const envs = useContext(EnvContext);

  if (!isOfferSheetPath(pathname)) {
    return <PrivateRoute {...props} />;
  }

  const isUserAllowedToViewOffers = isAllowedToViewOffers(
    userEmail,
    envs?.VITE_OFFER_TOOL_ALLOWED_USERS,
    envs?.HEROKU_PR_NUMBER,
  );

  if (!isUserAllowedToViewOffers) {
    setNotification({
      type: 'SNACKBAR',
      severity: 'error',
      duration: 4000,
      message: 'Ei käyttöoikeutta tarjoustyökaluun',
    });
  }

  return (
    <>
      {isUserAllowedToViewOffers ? (
        <PrivateRoute {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}
    </>
  );
};

const PrivateEditRoute = (props: RouteProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const { pathname } = useLocation();
  const { userId, userEmail } = useUserContext();
  const [isLoading, setIsloading] = useState(true);
  const [userAllowed, setIsUserAllowed] = useState(true);
  useEffect(() => {
    (async () => {
      if (isEditSheetPath(pathname) && userId) {
        try {
          const pathParts = pathname?.split('/') ?? [];
          const pricingSheetId = pathParts[pathParts?.length - 1];
          const pricingSheet =
            await getPricingSheetById(pricingSheetId);

          const isUserAllowedToEdit =
            Number(pricingSheet.userId) === userId ||
            isEditingRights(pricingSheet.editingRights, userEmail);

          if (!isUserAllowedToEdit) {
            setNotification({
              type: 'SNACKBAR',
              severity: 'error',
              duration: 4000,
              message:
                'Ainoastaan hinnaston tekijä voi muokata hinnastoa',
            });
            setIsUserAllowed(false);
          }

          if (isEditingLocked(pricingSheet, userEmail ?? '')) {
            setNotification({
              type: 'SNACKBAR',
              severity: 'error',
              duration: 4000,
              message: `${pricingSheet.editorFullName} on muokkaamassa hinnastoa. Hinnastoa voi muokata ainoastaan yksi henkilö kerrallaan`,
            });
            setIsUserAllowed(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsloading(false);
        }
      }
    })();
  }, [pathname, userId, setNotification, userEmail]);
  return (
    <>
      {userAllowed ? (
        !isLoading && <PrivateRoute {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}
    </>
  );
};

const Front = () => <Redirect to="/hinnastot" />;

const App = () => {
  const {
    userEmail,
    loggedIn,
    hasUnsavedChanges,
    setUserEmail,
    setUserId,
    setUserFirstName,
    setUserLastName,
    setLoggedIn,
    userFirstName,
    userLastName,
    isAdmin,
  } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);
  const { pathname } = useLocation();
  let history = useHistory();
  const [envs, setEnvs] = React.useState<Envs | null>(null);

  useEffect(() => {
    (async () => {
      const _envs = await getEnvs();
      setEnvs(_envs);
    })();
  }, []);

  const resetUserSessionClient = () => {
    setUserEmail(null);
    setLoggedIn(false);
    setUserFirstName(null);
    setUserLastName(null);
  };

  useEffect(() => {
    const checkSession = async () => {
      const session = await checkSessionStatus();
      if (session?.status !== 200) {
        setUserId(null);
        setUserEmail(null);
        setLoggedIn(false);
        resetUserSessionClient();
        setNotification({
          type: 'SNACKBAR',
          severity: 'error',
          message: 'Istunto vanheni!',
        });
      }
    };
    if (loggedIn && history?.action !== 'REPLACE') {
      checkSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(async () => {
      // Dont do the check on login page
      if (pathname !== '/') {
        const session = await checkSessionStatus();
        if (session?.status !== 200) {
          resetUserSessionClient();
          setNotification({
            type: 'SNACKBAR',
            severity: 'error',
            message: 'Istunto vanheni!',
          });
        }
      }
    }, 1000 * 60);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogoutClick = () => {
    setLoggedIn(false);
    setUserEmail(null);
    setUserId(null);
    setUserFirstName(null);
    setUserLastName(null);
    logout();
  };

  const [showProfile, setShowProfile] = useState(false);
  const [closeProfile, setCloseProfile] = useState(false);

  // Clicking profile icon without the timer would pop the profile back open
  useEffect(() => {
    if (closeProfile) {
      setShowProfile(false);
      setTimeout(() => {
        setCloseProfile(false);
      }, 100);
    }
  }, [closeProfile]);

  return (
    <div className="App">
      <EnvContext.Provider value={envs}>
        <LocalizationProvider
          adapterLocale={fi}
          dateAdapter={AdapterDateFns}
        >
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <header>
              <AppBar position="static" color="secondary">
                <Toolbar
                  sx={{ justifyContent: 'center', display: 'flex' }}
                >
                  <Box
                    sx={{
                      maxWidth: '90%',
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      textAlign: 'center',
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item marginRight={1}>
                        <Link to="/">
                          <Box
                            component="img"
                            sx={{
                              height: '25px',
                              pointerEvents: 'none',
                            }}
                            src={ramirentLogo}
                            alt="logo"
                          />
                        </Link>
                      </Grid>
                      {loggedIn && (
                        <>
                          <Grid item alignSelf={'center'}>
                            <Link
                              color="primary"
                              to="/uusi-hinnasto"
                              className="header-link"
                            >
                              Luo hinnasto
                            </Link>
                          </Grid>
                          <Grid item alignSelf={'center'}>
                            <Link
                              color="primary"
                              to="/hinnastot"
                              className="header-link"
                            >
                              Hinnastot
                            </Link>
                          </Grid>
                          {isAllowedToViewOffers(
                            userEmail,
                            envs?.VITE_OFFER_TOOL_ALLOWED_USERS,
                            envs?.HEROKU_PR_NUMBER,
                          ) && (
                            <>
                              <Grid item alignSelf={'center'}>
                                <Link
                                  color="primary"
                                  to="/tarjous"
                                  className="header-link"
                                >
                                  Luo tarjous
                                </Link>
                              </Grid>
                              <Grid item alignSelf={'center'}>
                                <Link
                                  color="primary"
                                  to="/tarjoukset"
                                  className="header-link"
                                >
                                  Tarjoukset
                                </Link>
                              </Grid>
                            </>
                          )}
                          {isAdmin && (
                            <Grid item alignSelf={'center'}>
                              <Link
                                color="primary"
                                to="/admin/1"
                                className="header-link"
                              >
                                Hallintapaneeli
                              </Link>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                    {loggedIn && (
                      <>
                        <Box
                          display="inline-flex"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (!closeProfile) {
                              setShowProfile(true);
                            }
                          }}
                        >
                          <Box marginRight={1}>
                            <Typography color="primary">
                              {userFirstName}
                            </Typography>
                          </Box>

                          <Person color="primary" />
                        </Box>
                        {showProfile && (
                          <ClickAwayListener
                            onClickAway={() => {
                              setCloseProfile(true);
                            }}
                          >
                            <Paper
                              sx={{
                                width: '80%',
                                maxWidth: 400,
                                position: 'absolute',
                                right: 0,
                                top: 60,
                                zIndex: 2,
                              }}
                              elevation={5}
                              className="profile"
                            >
                              <Box
                                display="flex"
                                paddingTop={2}
                                paddingLeft={2}
                                paddingRight={2}
                              >
                                <UserInformation
                                  firstName={userFirstName}
                                  lastName={userLastName}
                                  email={userEmail}
                                />
                              </Box>
                              <List aria-label="main mailbox folders">
                                <Divider />
                                <ListItem
                                  button
                                  onClick={() => {
                                    setShowProfile(false);
                                    handleLogoutClick();
                                  }}
                                >
                                  <ListItemIcon>
                                    <Lock />
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      color: 'text.primary',
                                    }}
                                    primary="Kirjaudu ulos"
                                  />
                                </ListItem>
                              </List>
                            </Paper>
                          </ClickAwayListener>
                        )}
                      </>
                    )}
                  </Box>
                </Toolbar>
              </AppBar>
            </header>
            <>
              {!loggedIn && userEmail === undefined ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 5,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={
                      processEnvs.REQUIRE_LOGIN === 'false'
                        ? Front
                        : Login
                    }
                  />
                  <PrivateRoute
                    exact
                    path="/index"
                    component={Front}
                  />
                  <BackgroundProvider>
                    {/* OFFER ROUTES */}
                    <PrivateOfferRoute
                      exact
                      path="/tarjous/:id/tarkastele/:approvalId?"
                      component={InspectOffer}
                    />
                    <PrivateOfferRoute
                      exact
                      path="/tarjous/:id"
                      component={EditOffer}
                    />
                    <PrivateOfferRoute
                      exact
                      path="/tarjous"
                      component={CreateOffer}
                    />
                    <PrivateOfferRoute
                      exact
                      path={`/tarjoukset/:tab(${getPathAlternatives(
                        OffersTableTabState,
                      )})?`}
                      component={OfferSheetsTable}
                    />
                    {/* END OFFER ROUTES */}
                    <PricingProvider>
                      <RowsProvider>
                        <ItemIndustryPercentsProvider>
                          <PrivateRoute
                            exact
                            path={`/admin/:tabId/:type(${getPathAlternatives(
                              SheetType,
                            )})?`}
                            component={Admin}
                          />
                          <PrivateRoute
                            exact
                            path="/uusi-hinnasto"
                            component={HorizontalNonLinearStepper}
                          />
                          <Prompt
                            when={hasUnsavedChanges}
                            message="Sinulla on tallentamattomia muutoksia. Haluatko varmasti poistua sivulta?"
                          />

                          <PrivateRoute
                            exact
                            path="/tarkastele-hinnastoa/:id"
                            component={InspectPricingSheetInit}
                          />
                          <PrivateRoute
                            exact
                            path="/tarkastele-hinnastoa/:id/hyvaksynta/:approvalId"
                            component={InspectPricingSheetInit}
                          />
                          <PrivateRoute
                            exact
                            path="/hinnastot"
                            component={PricingSheets}
                          />
                          <PrivateEditRoute
                            exact
                            path="/muokkaa-hinnastoa/:pricingSheetIdUrl"
                            component={EditPricingSheet}
                          />
                        </ItemIndustryPercentsProvider>
                      </RowsProvider>
                    </PricingProvider>
                  </BackgroundProvider>
                  <Route component={NotFound} />
                </Switch>
              )}
            </>
          </Sentry.ErrorBoundary>
        </LocalizationProvider>
      </EnvContext.Provider>
    </div>
  );
};

export default Notification(App);
