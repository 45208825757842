import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
} from 'react';
import { processEnvs } from '../../config/processEnvs';

import { checkSessionStatus } from '../../services/user';

import NotificationProvider from '../Notification/NotificationProvider';

import { UserRole } from '../../../../shared/types';

import isAdminUser from '../../helpers/isAdminUser';

import { Nullable } from '../../../../shared/types';

export type UserContextType = {
  userEmail: Nullable<string>;
  userId: Nullable<number>;
  setUserEmail: React.Dispatch<
    React.SetStateAction<Nullable<string>>
  >;
  setUserFirstName: React.Dispatch<
    React.SetStateAction<Nullable<string>>
  >;
  setUserLastName: React.Dispatch<
    React.SetStateAction<Nullable<string>>
  >;
  setUserId: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  userRoles: UserRole[] | undefined;
  isAdmin: boolean;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setUserPhoneNumber: React.Dispatch<
    React.SetStateAction<Nullable<string>>
  >;
  userFirstName: Nullable<string>;
  userLastName: Nullable<string>;
  userPhoneNumber: Nullable<string>;
};

const UserContext = createContext<UserContextType>(null!);

const UserProvider = (props: { children: ReactNode }) => {
  const [userEmail, setUserEmail] =
    useState<Nullable<string>>(undefined);
  const [userId, setUserId] = useState<Nullable<number>>(undefined);
  const [userFirstName, setUserFirstName] =
    useState<Nullable<string>>(undefined);
  const [userLastName, setUserLastName] =
    useState<Nullable<string>>(undefined);
  const [userPhoneNumber, setUserPhoneNumber] =
    useState<Nullable<string>>(undefined);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<UserRole[] | undefined>(
    undefined,
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<boolean>(false);

  const { setNotification } = useContext(NotificationProvider);

  const isAdmin = userRoles ? isAdminUser(userRoles) : false;

  const checkLoginStatus = async () => {
    const session = await checkSessionStatus();
    if (!session) {
      setNotification({
        severity: 'error',
        message:
          'Emme saa yhteyttä taustapalvelimeen. Ota yhteyttä järjestelmän ylläpitäjään.',
      });
    }
    if (session?.status === 200 && session.data?.user) {
      const user = JSON.parse(session.data.user);
      setUserEmail(user.email);
      setUserId(user.id);
      setUserFirstName(user.first_name);
      setUserLastName(user.last_name);
      setLoggedIn(true);
      setUserPhoneNumber(user.phone_number);
    } else {
      setUserEmail(null);
      setUserId(null);
      setLoggedIn(false);
      setUserFirstName(null);
      setUserLastName(null);
      setUserPhoneNumber(null);
    }
  };

  useEffect(() => {
    const setRoles = async () => {
      const session = await checkSessionStatus();

      if (session?.status === 200) {
        const user = JSON.parse(session.data.user);
        let roles = [];
        for (const role of Object.entries(user.roles.all)) {
          roles.push((role[1] as { name: UserRole }).name);
        }
        setUserRoles(roles as UserRole[]);
      }
    };
    if (loggedIn) {
      setRoles();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (processEnvs.REQUIRE_LOGIN === 'false') {
      setUserEmail('testuser');
      setLoggedIn(true);
    } else {
      checkLoginStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const children = props.children;

  return (
    <UserContext.Provider
      value={{
        userEmail,
        userId,
        setUserEmail,
        setUserFirstName,
        setUserLastName,
        setUserPhoneNumber,
        userPhoneNumber,
        setUserId,
        loggedIn,
        setLoggedIn,
        userRoles,
        isAdmin,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        userFirstName,
        userLastName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      'useUserContext was used outside of its Provider',
    );
  }

  return context;
};

export default useUserContext;

export { UserContext, UserProvider };
