import React from 'react';

import { Typography } from '@mui/material';

import {
  pricingValue,
  differenceInDays,
} from '../InitialInformationStep';

import { BackgroundInfo } from '../../../../../shared/types';

interface ValueProps {
  backgroundInfo: BackgroundInfo;
}
const Value: React.FC<ValueProps> = ({ backgroundInfo }) => {
  return (
    <Typography
      variant="h4"
      component="div"
      gutterBottom
      color="text.primary"
    >
      {pricingValue(
        backgroundInfo?.potential,
        differenceInDays(
          backgroundInfo?.priceSheetStartDate,
          backgroundInfo?.priceSheetEndDate,
        ),
      )}
    </Typography>
  );
};

export default Value;
