import {
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import {
  PropsWithChildren,
  ReactNode,
  useContext,
  useRef,
} from 'react';
import Button from '@mui/material/Button';
import {
  createProductListRequest,
  updateProductListRequest,
} from '../../../services/productLists';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import AgGrid, {
  CustomAgGridMethods,
} from '../../../components/PricingTable/AgGrid';
import { useColumns } from '../../../hooks/useColumns';
import {
  AgGridColumnView,
  PricingSheetRow,
  TableType,
} from '../../../../../shared/types';
import { useSortedGroupHierarchy } from '../../../hooks/useSortedGroupHierarchy';
import LoadingRenderer from '../../../components/Common/LoadingRenderer';
import {
  CreateProductListRequestBody,
  GetProductListItemsResponseBody,
  PartialProductListItem,
  ProductListItem,
  UpdateProductListRequestBody,
} from '../../../../../shared/types/productLists';
import { useQuery } from '@tanstack/react-query';
import { PRODUCT_LIST_ITEMS } from '../../../../../shared/constants';
import { api } from '../../..';
import { SimpleProductListView } from './SimpleProductListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProductListTooltip from '../../../components/Tooltips/ProductListTooltip';

type ConfirmationDialogProps = PropsWithChildren<
  DialogProps & {
    cancelButtonLabel?: string;
    cancelButtonProps?: ButtonProps;
    confirmButtonLabel?: string;
    confirmButtonProps?: ButtonProps;
    onCancel: (isDirty: boolean) => void;
    onConfirm: () => void;
    loading?: boolean;
    open: boolean;
    title: ReactNode;
    productListData?: ProductListProps;
  }
>;

type ProductListProps = {
  id?: number;
  name?: string;
  description?: string;
  productListItems: ProductListItem[];
};

type EditProductList = {
  id?: number;
  name?: string;
  description?: string;
  productListItems: PartialProductListItem[];
};

const INIT_STATE = {
  id: undefined,
  name: '',
  description: '',
  productListItems: [],
};

export const EditProductListDialog = ({
  children,
  cancelButtonLabel = 'Peruuta',
  cancelButtonProps,
  confirmButtonLabel = 'Hyväksy',
  confirmButtonProps,
  onConfirm = () => null,
  onCancel = () => null,
  loading = false,
  open,
  title,
  productListData,
  ...props
}: ConfirmationDialogProps) => {
  const agGridRef = useRef<CustomAgGridMethods>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const isEdit = productListData?.id;
  const productListId = productListData?.id;
  const isDirty = useRef<boolean>(false);

  const initProductListItems = (
    productListItems: ProductListItem[],
  ) => {
    let propertiesToRemove: (keyof ProductListItem)[] = [
      'createdAt',
      'updatedAt',
    ];
    return productListItems.map((productListItem) => {
      let newObj = { ...productListItem };
      propertiesToRemove.forEach((property) => {
        delete newObj[property];
      });
      return productListItem;
    });
  };

  const initialState: EditProductList = isEdit
    ? {
        id: productListData.id,
        description: productListData.description,
        name: productListData.name,
        productListItems: initProductListItems(
          productListData.productListItems,
        ),
      }
    : INIT_STATE;

  const { columnDefs, gridColumnView } = useColumns(
    AgGridColumnView.PRODUCT_LISTS,
    {
      handleUpdateRowValue: () => {
        isDirty.current = true;
      },
    },
  );
  const { isPending: isLoadingHierarchy } = useSortedGroupHierarchy();
  const { setNotification } = useContext(NotificationProvider);

  const { data, isFetching } = useQuery({
    queryKey: [PRODUCT_LIST_ITEMS, productListId],
    queryFn: async () =>
      (
        await api.get<GetProductListItemsResponseBody>(
          isEdit
            ? `/productlists/items/${productListId}`
            : `/productlists/items`,
        )
      ).data,
    retry: false,
  });

  const flatten: GetProductListItemsResponseBody | undefined =
    data?.map((datarow) => {
      if (isEdit) {
        return {
          ...datarow,
          amount: datarow?.productListItems[0]?.amount ?? null,
        };
      } else {
        return datarow;
      }
    });

  const formatUpdateRequestBody =
    (): UpdateProductListRequestBody | void => {
      return {
        id: productListData?.id ?? 1,
        name: nameRef.current?.value ?? '',
        description: descriptionRef.current?.value ?? '',
        productListItems: agGridRef.current
          ?.getSelectedRows()
          .map((row: any) => {
            return {
              itemId: row.id,
              amount: row.amount ?? 1,
              productListId: productListId,
            };
          }),
      };
    };

  const formatCreateRequestBody =
    (): CreateProductListRequestBody | void => {
      return {
        name: nameRef.current?.value ?? '',
        description: descriptionRef.current?.value ?? '',
        productListItems: agGridRef.current
          ?.getSelectedRows()
          .map((row: any) => {
            return {
              itemId: row.id,
              amount: row.amount ?? 1,
              productListId: productListId,
            };
          }),
      };
    };

  const handleOnSaveClick = async () => {
    try {
      if (isEdit) {
        const data = formatUpdateRequestBody();
        if (data) {
          await updateProductListRequest(data);
        }
      } else {
        const data = formatCreateRequestBody();
        if (data) {
          await createProductListRequest(data);
        }
      }
    } catch (error) {
      // todo: handle error case
    } finally {
      onConfirm();
      setNotification({
        type: 'SNACKBAR',
        severity: 'success',
        duration: 4000,
        message: isEdit
          ? 'Tuotelista päivitetty onnistuneesti'
          : 'Tuotelista luotu onnistuneesti',
      });
    }
  };

  const formatItem = (item: any): PricingSheetRow => {
    return {
      ...item,
      productListItems: initialState.productListItems.filter(
        (productListItem) => productListItem.itemId === item.id,
      ),
      pricingBasis: 'DAY',
      preSelected: Boolean(item.amount),
      pimProductGroup:
        item.pimProductGroup ?? 'Ryhmittelemättömät tuotteet',
      pimDisplayName: item.pimDisplayName ?? item.name,
    };
  };

  const handleRowSelected = (event: any) => {
    agGridRef?.current?.getSelectedNodes()?.forEach((node) => {
      if (node.isSelected() && !node?.data?.amount) {
        isDirty.current = true;
        node.setDataValue('amount', 1);
      }
    });
    if (!event.node.selected && event?.node?.data?.amount) {
      isDirty.current = true;
      event.node.setDataValue('amount', null);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      onClose={() => onCancel(isDirty.current)}
      open={open}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          pb: 2,
        }}
      >
        <Stack direction="column" spacing={2} sx={{ width: '60%' }}>
          <TextField
            onChange={() => {
              isDirty.current = true;
            }}
            defaultValue={initialState.name}
            label="Nimi*"
            variant="standard"
            fullWidth
            inputRef={nameRef}
          />
          <TextField
            onChange={() => {
              isDirty.current = true;
            }}
            defaultValue={initialState.description}
            label="Selite"
            variant="standard"
            fullWidth
            inputRef={descriptionRef}
          />
        </Stack>
        <Grid container flexDirection={'row'} alignContent={'center'}>
          <Grid item>
            <h3>Tuotelista</h3>
          </Grid>
          <Grid item>
            <ProductListTooltip
              title={
                <SimpleProductListView
                  getItems={() => {
                    return agGridRef.current?.getSelectedRows() ?? [];
                  }}
                  items={agGridRef.current?.getSelectedRows() ?? []}
                />
              }
            >
              <Grid>
                <InfoOutlinedIcon
                  color="info"
                  sx={{
                    fontSize: 24,
                    cursor: 'help',
                    ml: 1,
                    mt: 2.3,
                  }}
                />
              </Grid>
            </ProductListTooltip>
          </Grid>
        </Grid>
        {isFetching ? (
          <LoadingRenderer
            label="Ladataan tuotteita"
            sx={{ mt: 8 }}
          />
        ) : (
          <AgGrid
            readOnlyEdit={false}
            className={isLoadingHierarchy ? 'loading' : ''}
            hideSideBar
            ref={agGridRef}
            gridColumnView={gridColumnView}
            columnDefs={columnDefs}
            type={TableType.ProductLists}
            rows={flatten?.map(formatItem) ?? []}
            onRowSelectedCb={handleRowSelected}
            loading={false}
            externalFilters={[]}
            isSelectable={true}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'end',
          px: 3,
          pt: 0,
          pb: 2,
        }}
      >
        <Button
          disabled={loading}
          color={'inherit'}
          {...cancelButtonProps}
          onClick={() => onCancel(isDirty.current)}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant={'contained'}
          disabled={loading}
          endIcon={
            loading && <CircularProgress thickness={4} size={16} />
          }
          {...confirmButtonProps}
          onClick={handleOnSaveClick}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
