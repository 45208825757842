import { PricingSheetRow } from '../../../../../shared/types';
import { ValueGetterParams } from 'ag-grid-community';

const catClassValueGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  if (!params.data) return null;
  const { catClass, pimDisplayName } = params.data;
  return `${catClass} ${pimDisplayName}`;
};

export default catClassValueGetter;
