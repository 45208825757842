import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Typography } from '@mui/material';
import { PRODUCT_LISTS_QUERY_KEY } from '../../../../shared/constants';
import { useQuery } from '@tanstack/react-query';
import { api } from '../..';
import { OfferItem, PricingSheetRow } from '../../../../shared/types';
import { SimpleProductListView } from '../../pages/Admin/ProductList/SimpleProductListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProductListTooltip from '../Tooltips/ProductListTooltip';
import {
  DataBaseProductListWithItems,
  GetProductListsResponseBody,
} from '../../../../shared/types/productLists';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

interface SelectPreSelectionsProps {
  offerItems: OfferItem[];
  addSelectedRow: (
    row: PricingSheetRow,
    forceRender?: boolean,
  ) => void;
  getSelectedRef: () => (() => PricingSheetRow[]) | undefined;
}

const ProductListSelect = ({
  offerItems,
  getSelectedRef,
  addSelectedRow,
}: SelectPreSelectionsProps) => {
  const autocompleteRef = React.useRef<HTMLInputElement>(null);
  const { data: productLists = [] } = useQuery({
    queryKey: [PRODUCT_LISTS_QUERY_KEY],
    queryFn: async () =>
      (await api.get<GetProductListsResponseBody>(`/productlists`))
        .data,
  });
  const { setNotification } = React.useContext(NotificationProvider);

  const handleAddElement = (
    newProductList: DataBaseProductListWithItems,
  ) => {
    const getSelected = getSelectedRef();
    if (!getSelected) return;

    const newSelections = newProductList.productListItems.map(
      (productListItem) => {
        return {
          ...productListItem.item,
          quantity: productListItem.amount,
        };
      },
    );
    newSelections.forEach((selection) => {
      // offeritem includes prices so it is used instead
      const offeritem = offerItems.find(
        (offerItem) => offerItem.catClass === selection.catClass,
      );
      if (offeritem) {
        offeritem.quantity = selection.quantity;
        addSelectedRow(offeritem);
      }
    });
  };

  const handleProductListChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: DataBaseProductListWithItems | null,
  ) => {
    if (newValue === null) {
      return;
    }
    setNotification({
      type: 'MODAL',
      cancelText: 'Peruuta',
      acceptText: 'Tuo',
      icon: 'clipboard',
      severity: 'info',
      size: 'lg',
      NotificationBody: () => {
        return (
          <SimpleProductListView
            items={newValue.productListItems.map(
              (productListItem) => {
                return {
                  ...productListItem.item,
                  amount: productListItem.amount,
                };
              },
            )}
          />
        );
      },
      message: ` `,
      title: `Olet tuomassa ${newValue.productListItems.length} tuotetta tarjoukselle`,
      onAccept: () => {
        handleAddElement(newValue);
        setNotification({
          type: 'SNACKBAR',
          severity: 'success',
          duration: 4000,
          message: `Tuotelistan "${newValue.name}" tuotteet lisätty tarjoukselle`,
        });
      },
    });
  };

  return (
    <Autocomplete
      id="checkboxes-tags-demo"
      options={productLists}
      onChange={handleProductListChange}
      value={null}
      getOptionLabel={(option) => option.name}
      ref={autocompleteRef}
      size="small"
      style={{
        width: 600,
        marginLeft: 16,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Valitse tuotteet tuotelistalta"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <FormatListBulletedIcon fontSize={'small'} />
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <li key={option.name} {...optionProps}>
            <Grid
              container
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Grid display={'flex'} alignItems={'center'} item>
                <Typography>{option.name}</Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <Typography>
                    {`${option.productListItems.length} tuotetta`}
                  </Typography>
                  <ProductListTooltip
                    title={
                      <SimpleProductListView
                        items={option.productListItems.map(
                          (productListItem) => {
                            return {
                              ...productListItem.item,
                              amount: productListItem.amount,
                            };
                          },
                        )}
                      />
                    }
                  >
                    <InfoOutlinedIcon
                      color="info"
                      sx={{
                        fontSize: 18,
                        cursor: 'help',
                        ml: 1,
                        mt: 1,
                      }}
                    />
                  </ProductListTooltip>
                </Grid>
              </Grid>
              <Grid md={12} item>
                <Typography color={'text.secondary'}>
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default ProductListSelect;
