import AdminColumnsBoth from '../AdminColumns/AdminColumnsBoth';
import AdminColumnsCommon from '../AdminColumns/AdminColumnsCommon';
import AdminColumnsDay from '../AdminColumns/AdminColumnsDay';
import AdminColumnsHierarchy from '../AdminColumns/AdminColumnsHierarchy';
import AdminColumnsIndustryDay from '../AdminColumns/AdminColumnsIndustryDay';
import AdminColumnsIndustryMonth from '../AdminColumns/AdminColumnsIndustryMonth';
import AdminColumnsMonth from '../AdminColumns/AdminColumnsMonth';
import AdminColumnsCriticalEquipment from '../AdminColumns/AdminColumnsCriticalEquipment';
import AdminColumnsSurplusEquipment from '../AdminColumns/AdminColumnsSurplusEquipment';
import ColumnsBoth from './ColumnsBoth';
import ColumnsDay from './ColumnsDay';
import ColumnsMonth from './ColumnsMonth';
import ColumnsPreSelect from './ColumnsPreSelect';
import InspectColumnsBoth from './InspectColumnsBoth';
import InspectColumnsDay from './InspectColumnsDay';
import ColumnsOfferPreSelect from './OfferColumnsPreSelect';
import ColumnsOffer from './OfferColumns';
import OfferColumnsItemDetails from './OfferColumnsItemDetails';
import { AgGridColumnView } from '../../../../../shared/types';
import AdminColumnsKitNameList from '../AdminColumns/AdminColumnsTitleList';
import { OfferColumnsInspect } from './OfferColumnsInspect';
import ProductListColumns from '../AdminColumns/AdminProductLists';

export interface GridColumnProps<T = any> {
  classes: {
    headerCell: string;
    childHeaderCell: string;
    orangePriceCell: string;
    redPriceCell: string;
    pinkPercentCell: string;
    zeroPriceCell: string;
    editableCell?: string | undefined;
    priceCell: string;
    criticalPriceCell: string;
    invalidPriceThresholdCell: string;
  };
  gridGroupingValues: string[];
  optionalColumnProps: T;
}

export const viewToColumns = (view: AgGridColumnView) => {
  return {
    [AgGridColumnView.PRICING_DAY]: ColumnsDay,
    [AgGridColumnView.PRICING_MONTH]: ColumnsMonth,
    [AgGridColumnView.PRICING_BOTH]: ColumnsBoth,
    [AgGridColumnView.PRESELECT]: ColumnsPreSelect,
    [AgGridColumnView.ADMIN_DAY]: AdminColumnsDay,
    [AgGridColumnView.ADMIN_MONTH]: AdminColumnsMonth,
    [AgGridColumnView.ADMIN_BOTH]: AdminColumnsBoth,
    [AgGridColumnView.ADMIN_COMMON]: AdminColumnsCommon,
    [AgGridColumnView.ADMIN_HIERARCHY]: AdminColumnsHierarchy,
    [AgGridColumnView.ADMIN_INDUSTRY_DAY]: AdminColumnsIndustryDay,
    [AgGridColumnView.ADMIN_INDUSTRY_MONTH]:
      AdminColumnsIndustryMonth,
    [AgGridColumnView.ADMIN_KIT_NAME_LIST]: AdminColumnsKitNameList,
    [AgGridColumnView.INSPECT_DAY]: InspectColumnsDay,
    [AgGridColumnView.INSPECT_BOTH]: InspectColumnsBoth,
    [AgGridColumnView.OFFER_PRESELECT]: ColumnsOfferPreSelect,
    [AgGridColumnView.OFFER_PRICING]: ColumnsOffer,
    [AgGridColumnView.OFFER_ITEMDETAILS]: OfferColumnsItemDetails,
    [AgGridColumnView.OFFER_INSPECT]: OfferColumnsInspect,
    [AgGridColumnView.CRITICAL_EQUIPMENT]:
      AdminColumnsCriticalEquipment,
    [AgGridColumnView.SURPLUS_EQUIPMENT]:
      AdminColumnsSurplusEquipment,
    [AgGridColumnView.PRODUCT_LISTS]: ProductListColumns,
  }[view];
};

export const getColSpanValue = (type: AgGridColumnView): number => {
  switch (type) {
    case AgGridColumnView.ADMIN_COMMON:
      return 13;
    case AgGridColumnView.ADMIN_HIERARCHY:
      return 4;
    case AgGridColumnView.ADMIN_DAY:
    case AgGridColumnView.ADMIN_MONTH:
    case AgGridColumnView.ADMIN_INDUSTRY_DAY:
    case AgGridColumnView.ADMIN_INDUSTRY_MONTH:
      return 9;
    case AgGridColumnView.ADMIN_BOTH:
      return 17;
    case AgGridColumnView.CRITICAL_EQUIPMENT:
    case AgGridColumnView.SURPLUS_EQUIPMENT:
    case AgGridColumnView.OFFER_ITEMDETAILS:
      return 6;
    case AgGridColumnView.ADMIN_KIT_NAME_LIST:
      return 2;
    default:
      return 0;
  }
};
