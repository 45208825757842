import { DataTableRowProps } from '../../../components/Common/DataTable/DataTableRow';
import {
  DatabaseOfferWithApprovalRequest,
  OffersTableTabState,
  OfferState,
} from '../../../../../shared/types/offers';
import { convertDatabaseOfferDatesToDate } from '../../../helpers/convertDatabaseOfferDates';
import Box from '@mui/material/Box';
import { SvgIcon } from '@mui/material';
import { StateAlert } from '../../../components/Common/StateAlert';
import {
  getOfferStateIcon,
  getOfferStateLabel,
  getOfferStateSeverity,
} from '../helpers';
import BlockIcon from '@mui/icons-material/Block';
import { EventBusy, Inventory2Outlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';
import { RenderComponentProps } from './OfferSheetsTable';
import isWithinInterval from 'date-fns/isWithinInterval';
import { CUSTOMER_RESPONDED_OFFER_STATES } from '../../../../../shared/constants';
import DefaultTooltip from '../../../components/Tooltips/DefaultTooltip';
import { useTheme } from '@mui/material/styles';
import { getDateWithoutTime } from '../../../../../shared/utils/getDateWithoutTime';
import { calculateItemPrice } from '../../../../../shared/helpers/calculateItemPrice';
import { isCustomerBlocked } from '../../../helpers/isCustomerBlocked';
import { formatCustomerBlockingText } from '../../../helpers/formatCustomerBlockingText';
import { differenceInCalendarDays, subDays } from 'date-fns';

type OffersTableCellComponentProps = RenderComponentProps<
  DataTableRowProps<DatabaseOfferWithApprovalRequest>['renderCellComponent']
>;

export const OffersTableCellComponent: OffersTableCellComponentProps =
  ({ column, item }, { activeTab, loading = false }) => {
    const theme = useTheme();
    const { key } = column;
    const offer = convertDatabaseOfferDatesToDate(item);
    switch (key) {
      case 'userEmail':
      case 'name':
      case 'worksite':
        return (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 'max(9.5vw, 125px)',
            }}
          >
            <DefaultTooltip
              placement={'top'}
              enterDelay={400}
              enterNextDelay={400}
              title={offer[key] || ''}
            >
              <span>{offer[key]}</span>
            </DefaultTooltip>
          </Box>
        );
      case 'customerName':
        const isBlocked = isCustomerBlocked(offer);
        const infoTooltipText = formatCustomerBlockingText(offer);
        return (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'max(10.5vw, 125px)',
              }}
            >
              <DefaultTooltip
                placement={'top'}
                enterDelay={400}
                enterNextDelay={400}
                title={offer[key] || ''}
              >
                <span>{offer[key]}</span>
              </DefaultTooltip>
            </Box>
            {isBlocked && (
              <DefaultTooltip
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {infoTooltipText}
                  </span>
                }
              >
                <SvgIcon
                  color="error"
                  sx={{
                    fontSize: 16,
                  }}
                  component={BlockIcon}
                />
              </DefaultTooltip>
            )}
          </Box>
        );
      case 'state': {
        const { previousState, state } = item;
        let severity = getOfferStateSeverity(state);
        if (activeTab === OffersTableTabState.Archived) {
          severity = 'warning';
        }
        const label =
          activeTab === OffersTableTabState.Archived
            ? // label for archived offers
              `(${getOfferStateLabel(state)})`
            : state === OfferState.Expired
              ? // label for expired offers
                `(${getOfferStateLabel(previousState)})`
              : // label for other offer states
                getOfferStateLabel(state);
        const icon =
          activeTab === OffersTableTabState.Archived
            ? // icon for archived offers
              Inventory2Outlined
            : state === OfferState.Expired
              ? // icon for expired offers
                EventBusy
              : // icon for other offer states
                getOfferStateIcon(state);
        return (
          <StateAlert
            disabled={loading}
            severity={severity}
            label={label}
            icon={
              <SvgIcon
                sx={{
                  fontSize: 'inherit',
                }}
                component={icon}
              />
            }
          />
        );
      }
      case 'createdAt':
      case 'updatedAt':
        return (
          <Typography whiteSpace={'nowrap'} fontSize={'inherit'}>
            {formatDateTolocaleDateString(offer[key])}
          </Typography>
        );
      case 'offerValidityEnd': {
        const value = offer[key];

        switch (activeTab) {
          case OffersTableTabState.Sent:
          case OffersTableTabState.Incomplete: {
            const { offerValidityEnd, state } =
              convertDatabaseOfferDatesToDate(item);

            // don't show icon for offers that have been sent to customer
            if (CUSTOMER_RESPONDED_OFFER_STATES.includes(state)) {
              return formatDateTolocaleDateString(value);
            }

            const currentDateWithoutTime = getDateWithoutTime(
              new Date(),
            ).getTime();
            const offerValidityEndWithoutTime =
              getDateWithoutTime(offerValidityEnd).getTime();

            const hasExpired =
              offerValidityEndWithoutTime < currentDateWithoutTime;
            const warningDaysBeforeExpirationLimit = 2;

            // The three days: today, tomorrow or day after that
            const expiresInThreeDays = isWithinInterval(
              currentDateWithoutTime,
              {
                start: subDays(
                  offerValidityEndWithoutTime,
                  warningDaysBeforeExpirationLimit,
                ),
                end: offerValidityEndWithoutTime,
              },
            );
            const severity = hasExpired
              ? 'error'
              : expiresInThreeDays
                ? 'warning'
                : 'success';
            const infoTooltipText = hasExpired
              ? `Tarjous on vanhentunut!`
              : expiresInThreeDays
                ? `Tarjous vanhenee ${differenceInCalendarDays(offerValidityEndWithoutTime, currentDateWithoutTime) + 1} päivän sisällä.`
                : null;

            return (
              <StateAlert
                severity={severity}
                label={formatDateTolocaleDateString(value)}
                {...(infoTooltipText && {
                  infoTooltipColor: 'info',
                  infoTooltipText,
                })}
                // remove background color
                sx={{
                  backgroundColor: 'transparent',
                  color: 'text.primary',
                  p: 0,
                  '.MuiAlert-message': {
                    [theme.breakpoints.down('xl')]: {
                      fontSize: '0.75rem',
                    },
                  },
                }}
              />
            );
          }
          default:
            return formatDateTolocaleDateString(value);
        }
      }
      case 'totalPrice':
      case 'totalCoefficient':
        return (
          <Typography whiteSpace={'nowrap'} fontSize={'inherit'}>
            {`${calculateItemPrice(offer[key])} ${key === 'totalPrice' ? '€' : ''}`}
          </Typography>
        );
      default:
        return <>{offer[key]}</>;
    }
  };
