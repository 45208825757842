import { useContext, useEffect, useState } from 'react';
import PricingProvider from '../../providers/Pricing/PricingProvider';
import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import { useParams } from 'react-router-dom';
import { getPricingSheetById } from '../../services/pricingSheets';
import { currencyMultiplier } from '../../../../shared/constants';
import LoadingRenderer from '../../components/Common/LoadingRenderer';
import { Box } from '@mui/system';
import { PricingSheet } from '../../../../shared/types';
import InspectPricingSheet from './InspectPricingSheet';

const formatInitialBackgroundEditState = (
  pricingSheet: PricingSheet,
) => {
  return {
    name: pricingSheet.name,
    customerName: pricingSheet.customerName,
    customerId: pricingSheet.customerId,
    pdfCustomerName: pricingSheet.pdfCustomerName,
    priceSheetStartDate:
      pricingSheet.from && new Date(pricingSheet.from),
    priceSheetEndDate: pricingSheet.to && new Date(pricingSheet.to),
    priceSheetType: pricingSheet.level,
    state: pricingSheet.state,
    industry: pricingSheet.industry,
    revenue:
      pricingSheet.revenue &&
      pricingSheet.revenue / currencyMultiplier,
    potential: {
      bottom:
        pricingSheet.potentialBottom &&
        pricingSheet.potentialBottom / currencyMultiplier,
      top:
        pricingSheet.potentialTop &&
        pricingSheet.potentialTop / currencyMultiplier,
    },
    pricingBasis: pricingSheet?.pricingBasis ?? 'BOTH',
    region: pricingSheet.region,
    site: pricingSheet.site,
    nationWide: pricingSheet.nationWide,
    regionallySignificant: pricingSheet.regionallySignificant,
    strategicallySignificant: pricingSheet.strategicallySignificant,
    commitment: pricingSheet.commitment,
    paymentTermDays: pricingSheet.paymentTermDays,
    creatorComment: pricingSheet.creatorComment,
    approverComment: pricingSheet.approverComment,
    roundingBasisSheet: pricingSheet.roundingBasisSheet
      ? pricingSheet.roundingBasisSheet
      : 'TWODECIMALS',
    roundingBasisPdf: pricingSheet.roundingBasisPdf
      ? pricingSheet.roundingBasisPdf
      : 'TWODECIMALS',
    editingRights: pricingSheet.editingRights,
    userId: pricingSheet.userId,
  };
};

const InspectPricingSheetInit: React.FC = () => {
  const { id: pricingSheetIdUrl } = useParams<{
    id: string;
  }>();
  const { setPricingSheetId } = useContext(PricingProvider);
  const { setBackgroundInfo } = useContext(BackgroundProvider);

  const [isLoadingBackgroundState, setIsLoadingBackgroundState] =
    useState(true);

  useEffect(() => {
    if (pricingSheetIdUrl) {
      (async () => {
        try {
          setIsLoadingBackgroundState(true);
          const pricingSheet =
            await getPricingSheetById(pricingSheetIdUrl);
          setBackgroundInfo(
            formatInitialBackgroundEditState(pricingSheet),
          );

          setPricingSheetId(pricingSheetIdUrl);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingBackgroundState(false);
        }
      })();
    }
  }, [pricingSheetIdUrl, setBackgroundInfo, setPricingSheetId]);
  return (
    <>
      {isLoadingBackgroundState ? (
        <Box sx={{ mt: 20, mb: 70 }}>
          <LoadingRenderer label="Alustetaan hinnastoa" />
        </Box>
      ) : (
        <InspectPricingSheet />
      )}
    </>
  );
};

export default InspectPricingSheetInit;
