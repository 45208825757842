import DefaultTooltip, {
  DefaultTooltipProps,
} from './DefaultTooltip';

const ProductListTooltip = ({ ...props }: DefaultTooltipProps) => {
  return (
    <DefaultTooltip
      maxHeight={500}
      overflow={'scroll'}
      backgroundColor={'white'}
      {...props}
    />
  );
};
export default ProductListTooltip;
