import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  CustomerType,
  IndustryName,
  Language,
  Nullable,
  PriceCategory,
  PricingBasis,
} from '../../../../shared/types';
import CustomerSearchSelector, {
  ClientOption,
} from '../../components/Steps/Components/Selectors/CustomerSearchSelector';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import BackgroundInfoInputLabel from '../../components/Steps/Components/BackgroundInfoInputLabel';
import IndustrySelector from '../../components/Steps/Components/Selectors/IndustrySelector';
import PriceCategorySelector from '../../components/Steps/Components/Selectors/PriceCategorySelector';
import {
  ClientOffer,
  DatabaseOfferWithItems,
  OfferState,
} from '../../../../shared/types/offers';
import { produce } from 'immer';
import OfferStepActionContainer from './OfferStepActionContainer';
import { convertDatabaseOfferDatesToDate } from '../../helpers/convertDatabaseOfferDates';
import { OfferDatesSelector } from './OfferDatesSelector';
import PricingBasisSelector from '../../components/Steps/Components/Selectors/PricingBasisSelector';
import { OfferStepTitle } from './OfferStepTitle';
import { EnvContext } from '../../App';
import { setDatetoStartOfUTCDay } from '../../utils/formatDateTimes';
import DealProbabilitySelector from '../../components/Steps/Components/Selectors/DealProbabilitySelector';
import useUserContext from '../../providers/User/UserProvider';
import { isValidEmail } from '../../utils/isValidEmail';
import DefaultTooltip from '../../components/Tooltips/DefaultTooltip';
import { InfoOutlined } from '@mui/icons-material';

const INIT_OFFER_STATE: ClientOffer = {
  archivedAt: null,
  customerId: undefined,
  customerName: '',
  customerType: CustomerType.PRIVATE,
  pdfCustomerName: '',
  industry: undefined,
  items: [],
  name: '',
  offerLeasePeriodStart: setDatetoStartOfUTCDay(new Date()),
  offerLeasePeriodEnd: setDatetoStartOfUTCDay(new Date()),
  offerValidityStart: setDatetoStartOfUTCDay(new Date()),
  offerValidityEnd: setDatetoStartOfUTCDay(new Date()),
  priceCategory: PriceCategory.PriceCategory7,
  pricingBasis: 'DAY',
  state: OfferState.Draft,
  worksite: undefined,
  worksiteStreetAddress: '',
  worksiteZipCode: '',
  worksiteCity: '',
  receiverName: '',
  receiverEmail: '',
  receiverPhone: '',
  deliveryAddressStreet: '',
  deliveryAddressZipCode: '',
  deliveryAddressCity: '',
  responsibleInventSiteName: '',
  responsibleInventSiteStreetAddress: '',
  responsibleInventSiteZipCode: '',
  responsibleInventSiteCity: '',
  responsibleInventSitePhone: '',
  includeRamiturva: true,
  referenceComment: '',
  language: 'fi',
  dealProbability: undefined,
};

type CustomTextFieldProps = {
  [key: string]: any;
};

const getDefaultTextFieldProps = (
  customProps: CustomTextFieldProps = {},
): TextFieldProps => {
  return {
    fullWidth: true,
    size: 'small',
    sx: {
      my: 1,
    },
    ...customProps,
  };
};

type OfferDetailsProps = {
  offerSheet?: DatabaseOfferWithItems;
  renderStepActions: (state: ClientOffer) => ReactNode;
};

export const OfferDetails = ({
  offerSheet,
  renderStepActions = () => null,
}: OfferDetailsProps) => {
  const envs = useContext(EnvContext);
  const { userEmail, userFirstName, userLastName, userPhoneNumber } =
    useUserContext();

  const initOfferState = Object.assign(INIT_OFFER_STATE, {
    sellerName: userFirstName + ' ' + userLastName,
    sellerEmail: userEmail,
    sellerPhoneNumber: userPhoneNumber,
  });

  const [state, setState] = useState<ClientOffer>(
    offerSheet
      ? convertDatabaseOfferDatesToDate(offerSheet)
      : initOfferState,
  );
  const {
    customerId,
    customerName,
    industry,
    name,
    pdfCustomerName,
    priceCategory,
    worksite,
    pricingBasis,
    worksiteStreetAddress,
    worksiteZipCode,
    worksiteCity,
    receiverName,
    receiverEmail,
    receiverPhone,
    deliveryAddressStreet,
    deliveryAddressZipCode,
    deliveryAddressCity,
    responsibleInventSiteName,
    responsibleInventSiteStreetAddress,
    responsibleInventSiteZipCode,
    responsibleInventSiteCity,
    responsibleInventSitePhone,
    referenceComment,
    language,
    dealProbability,
    sellerName,
    sellerEmail,
    sellerPhoneNumber,
  } = state;

  const handleCustomerChange = useCallback(
    (clientOption: Nullable<ClientOption>) => {
      if (!clientOption?.value) {
        return;
      }
      const { id, type, value } = clientOption;
      setState(
        produce((state) => {
          state.customerId = id;
          state.customerName = value;
          state.customerType = type;
          state.pdfCustomerName = value;
        }),
      );
    },
    [],
  );

  const handleTextFieldChange = useCallback(
    (
      field:
        | 'name'
        | 'pdfCustomerName'
        | 'worksite'
        | 'worksiteStreetAddress'
        | 'worksiteZipCode'
        | 'worksiteCity'
        | 'deliveryAddressStreet'
        | 'deliveryAddressZipCode'
        | 'deliveryAddressCity'
        | 'receiverName'
        | 'receiverEmail'
        | 'receiverPhone'
        | 'responsibleInventSiteName'
        | 'responsibleInventSiteStreetAddress'
        | 'responsibleInventSiteZipCode'
        | 'responsibleInventSiteCity'
        | 'responsibleInventSitePhone'
        | 'referenceComment'
        | 'sellerName'
        | 'sellerEmail'
        | 'sellerPhoneNumber',
    ) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setState(
          produce((state) => {
            const value = event.target.value;
            if (field === 'worksite' && !value) {
              state.worksite = undefined;
            } else {
              state[field] = value;
            }
          }),
        );
      },
    [],
  );

  const handleDealProbabilityChange = (value: string) => {
    setState(
      produce((state) => {
        const numValue = Math.round(Number(value));

        if (value === '') {
          state['dealProbability'] = undefined;
        } else if (numValue >= 0 && numValue <= 100) {
          state['dealProbability'] = numValue;
        } else if (numValue > 100) {
          state['dealProbability'] = 100;
        }
      }),
    );
  };

  const handleCategoryChange = useCallback((value: PriceCategory) => {
    setState(
      produce((state) => {
        state.priceCategory = Number(value);
      }),
    );
  }, []);

  const handleIndustryChange = useCallback(
    (newValue: IndustryName) => {
      setState(
        produce((state) => {
          state.industry = newValue;
        }),
      );
    },
    [],
  );

  const handlePricingBasisChange = useCallback(
    (newValue: PricingBasis) => {
      setState(
        produce((state) => {
          state.pricingBasis = newValue;
        }),
      );
    },
    [],
  );

  const handleLanguageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newLanguage = event.target.value as Language;
      setState(
        produce((draft) => {
          draft.language = newLanguage;
        }),
      );
    },
    [],
  );

  const handleRamiturvaChange = useCallback((newValue: boolean) => {
    setState(
      produce((state) => {
        state.includeRamiturva = newValue;
      }),
    );
  }, []);

  return (
    <>
      <OfferStepActionContainer>
        {renderStepActions(state)}
      </OfferStepActionContainer>
      <OfferStepTitle title={'Asiakkaan perustiedot'} />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={6}
      >
        {/** First column */}
        <Grid item xl={3} lg={6} xs={12}>
          {/* OFFER NAME */}
          <BackgroundInfoInputLabel label="Tarjouksen nimi*" />
          <TextField
            label={'Nimi'}
            value={name ?? ''}
            onChange={handleTextFieldChange('name')}
            {...getDefaultTextFieldProps()}
          />
          {/* CUSTOMER */}
          <Box maxWidth={320}>
            <BackgroundInfoInputLabel label="Asiakas*" />
            <CustomerSearchSelector
              customerName={customerName ?? ''}
              customerId={customerId ?? ''}
              onCustomerChange={handleCustomerChange}
            />
            <TextField
              label={'Asiakkaan nimi tarjouksen kannessa'}
              value={pdfCustomerName ?? ''}
              onChange={handleTextFieldChange('pdfCustomerName')}
              {...getDefaultTextFieldProps()}
            />
          </Box>
          {/* INDUSTRY */}
          <BackgroundInfoInputLabel label="Asiakkaan toimiala*" />
          <Box sx={{ my: 1 }}>
            <IndustrySelector
              onIndustryChange={handleIndustryChange}
              industry={industry}
              changeValuesEnabled={false}
            />
          </Box>
          {/* WORKSITE */}
          <BackgroundInfoInputLabel label="Asiakkaan työmaa" />
          <TextField
            label={'Työmaan nimi'}
            value={worksite ?? ''}
            onChange={handleTextFieldChange('worksite')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Katuosoite'}
            value={worksiteStreetAddress ?? ''}
            onChange={handleTextFieldChange('worksiteStreetAddress')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postinumero'}
            value={worksiteZipCode ?? undefined}
            onChange={handleTextFieldChange('worksiteZipCode')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postitoimipaikka'}
            value={worksiteCity ?? ''}
            onChange={handleTextFieldChange('worksiteCity')}
            {...getDefaultTextFieldProps()}
          />
        </Grid>
        {/** Second column */}
        <Grid item xl={3} lg={6} xs={12}>
          {/* RENTAL DATES */}
          <OfferDatesSelector
            offerSheet={offerSheet}
            initialValues={{
              offerLeasePeriodStart: state.offerLeasePeriodStart,
              offerLeasePeriodEnd: state.offerLeasePeriodEnd,
              offerValidityStart: state.offerValidityStart,
              offerValidityEnd: state.offerValidityEnd,
            }}
            onChange={(dates) => setState({ ...state, ...dates })}
          />
          <BackgroundInfoInputLabel label="Asiakkaan hintakategoria*" />
          <PriceCategorySelector
            value={priceCategory}
            onCategoryChange={handleCategoryChange}
          />
          <BackgroundInfoInputLabel label="Tarjouksen hinnoitteluperuste*" />
          <PricingBasisSelector
            value={pricingBasis}
            onPricingBasisChange={handlePricingBasisChange}
          />
          {/* PROBABILITY OF A DEAL */}
          <Box
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <BackgroundInfoInputLabel label="Kaupan todennäköisyys*" />{' '}
            <DefaultTooltip
              title={
                'Arvio kaupan toteutumisen todennäköisyydestä. Arvion voi valita pudotusvalikosta tai syöttää tarkan arvon "Todennäköisyys %"-kenttään. '
              }
              placement="top"
            >
              <InfoOutlined
                color={'info'}
                sx={{
                  cursor: 'help',
                  fontSize: 20,
                  ml: 1,
                  mt: 0.5,
                }}
              />
            </DefaultTooltip>
          </Box>
          <DealProbabilitySelector
            value={dealProbability}
            onDealProbabilityChange={handleDealProbabilityChange}
          />
          <FormLabel component="legend" sx={{ fontSize: 16 }}>
            Todennäköisyys %
          </FormLabel>
          <TextField
            type="number"
            inputProps={{ min: '0', max: '100', step: '1' }}
            sx={{ width: 200 }}
            value={dealProbability?.toString()}
            onChange={(event) =>
              handleDealProbabilityChange(event.target.value)
            }
            {...getDefaultTextFieldProps({ fullWidth: false })}
          />
          {/* RAMITURVA */}
          <BackgroundInfoInputLabel label="RamiTurva" />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.includeRamiturva}
                onChange={(event) =>
                  handleRamiturvaChange(event.target.checked)
                }
              />
            }
            label={
              <Typography color="text.primary">
                {'Hinnoitellaanko tuotteille RamiTurva?'}
              </Typography>
            }
          />
        </Grid>
        {/** Third column */}
        <Grid item xl={3} lg={6} xs={12}>
          {/* RECEIVER INFORMATION */}
          <BackgroundInfoInputLabel label="Asiakkaan yhteyshenkilö" />
          <TextField
            label={'Nimi'}
            value={receiverName ?? ''}
            onChange={handleTextFieldChange('receiverName')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Sähköposti'}
            value={receiverEmail ?? ''}
            onChange={handleTextFieldChange('receiverEmail')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Puhelinnumero'}
            value={receiverPhone ?? ''}
            onChange={handleTextFieldChange('receiverPhone')}
            {...getDefaultTextFieldProps()}
          />
          <BackgroundInfoInputLabel label="Toimitustiedot" />
          <TextField
            label={'Katuosoite'}
            value={deliveryAddressStreet ?? ''}
            onChange={handleTextFieldChange('deliveryAddressStreet')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postinumero'}
            value={deliveryAddressZipCode ?? undefined}
            onChange={handleTextFieldChange('deliveryAddressZipCode')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postitoimipaikka'}
            value={deliveryAddressCity ?? ''}
            onChange={handleTextFieldChange('deliveryAddressCity')}
            {...getDefaultTextFieldProps()}
          />
          <Box
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <BackgroundInfoInputLabel label="Ramirentin yhteyshenkilö" />
            <DefaultTooltip
              title={
                'Yhteyshenkilön tiedot näkyvät hinnaston tulosteella'
              }
              placement="top"
            >
              <InfoOutlined
                color={'info'}
                sx={{
                  cursor: 'help',
                  fontSize: 20,
                  ml: 1,
                  mt: 0.5,
                }}
              />
            </DefaultTooltip>
          </Box>
          <TextField
            label={'Nimi'}
            value={sellerName ?? userFirstName + ' ' + userLastName}
            onChange={handleTextFieldChange('sellerName')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Sähköposti'}
            error={
              !!sellerEmail &&
              sellerEmail?.length > 0 &&
              !isValidEmail(sellerEmail)
            }
            helperText={
              sellerEmail &&
              sellerEmail?.length > 0 &&
              !isValidEmail(sellerEmail)
                ? 'Varmista että sähköpostiosoite on oikein'
                : null
            }
            value={sellerEmail ?? userEmail}
            onChange={handleTextFieldChange('sellerEmail')}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Puhelinnumero'}
            value={sellerPhoneNumber ?? userPhoneNumber}
            onChange={handleTextFieldChange('sellerPhoneNumber')}
            {...getDefaultTextFieldProps()}
          />
        </Grid>
        {/* FOURTH COLUMN */}
        <Grid item xl={3} lg={6} xs={12}>
          {/* RESPONSIBLEINVENTSITE INFORMATION */}
          <BackgroundInfoInputLabel label="Ramirent vuokraamo" />
          <TextField
            label={'Vuokraamon nimi'}
            value={responsibleInventSiteName ?? ''}
            onChange={handleTextFieldChange(
              'responsibleInventSiteName',
            )}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Katuosoite'}
            value={responsibleInventSiteStreetAddress ?? ''}
            onChange={handleTextFieldChange(
              'responsibleInventSiteStreetAddress',
            )}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postinumero'}
            value={responsibleInventSiteZipCode ?? ''}
            onChange={handleTextFieldChange(
              'responsibleInventSiteZipCode',
            )}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Postitoimipaikka'}
            value={responsibleInventSiteCity ?? ''}
            onChange={handleTextFieldChange(
              'responsibleInventSiteCity',
            )}
            {...getDefaultTextFieldProps()}
          />
          <TextField
            label={'Vuokraamon puhelinnumero'}
            value={responsibleInventSitePhone ?? ''}
            onChange={handleTextFieldChange(
              'responsibleInventSitePhone',
            )}
            {...getDefaultTextFieldProps()}
          />
          {/* MISC */}
          <BackgroundInfoInputLabel label="Muita valintoja" />
          <FormControl component="fieldset">
            <Grid container alignItems="center">
              <Grid item mr={1}>
                <FormLabel component="legend">PDF kieli:</FormLabel>
              </Grid>
              <Grid item>
                <RadioGroup
                  name="language"
                  value={language}
                  onChange={handleLanguageChange}
                  row
                >
                  <FormControlLabel
                    value="fi"
                    control={<Radio />}
                    label="Suomi 🇫🇮"
                  />
                  <FormControlLabel
                    value="en"
                    control={<Radio />}
                    label="Englanti 🇬🇧"
                  />
                  <FormControlLabel
                    value="sv"
                    style={{
                      display:
                        envs &&
                        envs.VITE_FEATURE_FLAG_HIDE_SWEDISH_PDF ===
                          'true'
                          ? 'none'
                          : '',
                    }}
                    control={<Radio />}
                    label="Ruotsi 🇸🇪"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
          <TextField
            rows={4}
            multiline
            label={'Viite kenttä'}
            placeholder='Vapaampi tekstikenttä, näkyy tulosteella "Viite" kentässä, voit esimerkiksi kirjoittaa tarjouksen lisätietoja tähän'
            value={referenceComment ?? ''}
            onChange={handleTextFieldChange('referenceComment')}
            {...getDefaultTextFieldProps()}
          />
        </Grid>
      </Grid>
    </>
  );
};
