import {
  Nullable,
  PricingSheetRow,
} from '../../../../../shared/types';

const requiredRateOfReturnGetter = (
  params: { data: PricingSheetRow },
  key: string,
) => {
  const value = params?.data
    ? (params?.data[key as keyof PricingSheetRow] as Nullable<number>)
    : undefined;
  return value ? (value / 100).toFixed(2) : '';
};

export default requiredRateOfReturnGetter;
