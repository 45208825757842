import { api } from '../index';
import { PriceApiResponse } from '../../../shared/types/price';
import { useQuery } from '@tanstack/react-query';
import {
  ITEM_PRICE_QUERY_KEY,
  PRICE_FACTOR,
} from '../../../shared/constants';
import { useUpdateOfferItems } from './useUpdateOfferItems';
import { DatabaseOfferWithItems } from '../../../shared/types/offers';
import { useItems } from './useItems';
import { useRef } from 'react';
import { OfferItem } from '../../../shared/types';
import { isSalesItem } from '../../../shared/helpers/isSalesItem';

const salesItemWithoutPricing = ({
  type,
  proposalDayPrice,
}: OfferItem) =>
  isSalesItem(type) &&
  (proposalDayPrice == null || proposalDayPrice === 0);

export const useUpdateSalesItemPricing = (
  offerSheet: DatabaseOfferWithItems,
): boolean => {
  // update offer sheet items mutation
  const {
    mutate: updateOfferItems,
    isPending: isUpdatingOfferItems,
  } = useUpdateOfferItems();
  const isPriceUpdated = useRef(false);
  // get offer sheet item data
  const { data: offerSheetItems = [] } = useItems({
    includeHidden: true,
    offerSheetItemsOnly: true,
    offerSheet,
  });
  // get sales items without pricing
  const offerSheetSalesItemsWithoutPricing = offerSheetItems.filter(
    salesItemWithoutPricing,
  );
  // item numbers of sales items without pricing
  const salesItemItemNumbers = offerSheetSalesItemsWithoutPricing.map(
    ({ catClass }) => catClass,
  );
  // fetch prices
  const { data, isFetching: isFetchingPrices } = useQuery({
    queryKey: [ITEM_PRICE_QUERY_KEY, salesItemItemNumbers],
    queryFn: async () =>
      (
        await api.get<PriceApiResponse[]>('/items/mira/price', {
          params: new URLSearchParams(
            salesItemItemNumbers.map((id) => `id=${id}`).join('&'),
          ),
        })
      ).data,
    enabled: salesItemItemNumbers.length > 0,
    staleTime: Infinity,
    retry: false,
  });
  // create {itemNumber: price} mapping
  const pricing =
    data?.reduce(
      (acc, { id, pricing }) => {
        return id
          ? {
              ...acc,
              // sales items only have one price as they don't have a tariff unit,
              // so we can safely take the first price
              [id]:
                // Null check added since price was not found for one user. Couldn't reproduce the bug
                pricing && pricing.length > 0
                  ? pricing[0].price * PRICE_FACTOR
                  : 1 * PRICE_FACTOR,
            }
          : acc;
      },
      {} as Record<string, number>,
    ) || {};
  // update offer sheet items with pricing
  if (!isPriceUpdated.current && Object.keys(pricing).length > 0) {
    const updatedDatabaseOfferItems = offerSheet.items.flatMap(
      (item) => {
        const catClass = offerSheetSalesItemsWithoutPricing.find(
          ({ offerItemId }) => offerItemId === item.id,
        )?.catClass;
        return catClass
          ? {
              ...item,
              dayPrice: pricing[catClass],
              monthPrice: pricing[catClass],
              proposalDayPrice: pricing[catClass],
              proposalMonthPrice: pricing[catClass],
            }
          : [];
      },
    );
    updateOfferItems({
      id: String(offerSheet.id),
      items: updatedDatabaseOfferItems,
    });
    isPriceUpdated.current = true;
  }
  return isUpdatingOfferItems || isFetchingPrices;
};
