import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';

import {
  Divider,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import PricingProvider from '../../providers/Pricing/PricingProvider';

import getPriceSheetTypeAbbreviation from '../../helpers/getPriceSheetTypeAbbrevation';
import PaymentTermSelector from './Components/Selectors/PaymentTermSelector';

import AcceptanceMatrix from './Components/AcceptanceMatrix';

import GenericCheckbox from './Components/Checkboxes/GenericCheckbox';
import ChangeValuesButton from './Components/Buttons/ChangeValuesButton';

import Header from './Components/Header';
import Revenue from './Components/Revenue';
import Potential from './Components/Potential';
import Commitment from './Components/Commitment';
import Level from './Components/Level';

import Site from './Components/Site';

import Dates from './Components/Dates';
import Value from './Components/Value';
import Duration from './Components/Duration';

import { useHistory } from 'react-router-dom';
import { currencyMultiplier } from '../../../../shared/constants';
import CustomerSearchSelector from './Components/Selectors/CustomerSearchSelector';
import CustomerWriteSelector from './Components/Selectors/CustomerWriteSelector';
import { Nullable } from '../../../../shared/types';
import { setDatetoStartOfUTCDay } from '../../utils/formatDateTimes';
import BackgroundInfoInputLabel from './Components/BackgroundInfoInputLabel';
import { isValidEmail } from '../../utils/isValidEmail';
import RegionSelector from './Components/Selectors/RegionSelector';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { InfoOutlined } from '@mui/icons-material';
import IndustrySelector from './Components/Selectors/IndustrySelector';

interface InitialInformationStepProps {}

export type HandleDateChange = (date: Date | null) => void;

export const asCurrency = new Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
  maximumSignificantDigits: 3,
});

export type ControlledSelector<T> = {
  value: T;
  onChange: (newValue: T) => void;
};

export const formatRevenue = (revenue: number) => {
  return `${asCurrency.format(revenue * currencyMultiplier)}`;
};

export const formatRentalPotential = (start: number, end: number) => {
  return `${asCurrency.format(
    start * currencyMultiplier,
  )} - ${asCurrency.format(end * currencyMultiplier)}`;
};

export const differenceInDays = (start: Date, end: Date) => {
  const differenceInTime = end?.getTime() - start?.getTime();
  return Math.floor(differenceInTime / (1000 * 3600 * 24) + 1);
};

export const differenceInDaysString = (start: Date, end: Date) => {
  return `${differenceInDays(start, end).toString()} päivää`;
};

export const pricingValue = (
  potential: { bottom: number; top: number },
  durationInDays: number,
) => {
  const bottomValue = Math.floor(
    potential?.bottom * currencyMultiplier * (durationInDays / 365),
  );
  const topValue = Math.floor(
    potential?.top * currencyMultiplier * (durationInDays / 365),
  );

  return `${asCurrency.format(bottomValue)} - ${asCurrency.format(
    topValue,
  )}`;
};

export const SubtleText: typeof Typography = (
  props: TypographyProps,
) => (
  <Typography
    sx={{ m: 1 }}
    variant="h6"
    component="div"
    gutterBottom
    color="primary"
  >
    {props.children}
  </Typography>
);

const InitialInformationStep: React.FC<
  InitialInformationStepProps
> = () => {
  let history = useHistory();

  const { backgroundInfo, setBackgroundInfo, resetBackgroundInfo } =
    useContext(BackgroundProvider);
  const { sellerEmail, sellerName, sellerPhoneNumber } =
    backgroundInfo;
  const { setNotification } = useContext(NotificationProvider);
  const { pricingSheetId } = useContext(PricingProvider);

  const { updateInitialInfoEnabled, setUpdateInitialInfoEnabled } =
    useContext(BackgroundProvider);
  const [name, setName] = useState<string | undefined>(
    backgroundInfo?.name,
  );

  const handlePropChange = <T,>(propName: string) => {
    return (value: T | string) => {
      const newState = { ...backgroundInfo };
      newState[propName] = value;
      setBackgroundInfo(newState);

      // ensure that end date is not before start date
      const startDate = newState['priceSheetStartDate'];
      const endDate = newState['priceSheetEndDate'];
      if (endDate < startDate) {
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'warning',
          message: 'Päättymispäivä ei voi olla ennen alkamispäivää',
        });
        newState['priceSheetEndDate'] =
          newState['priceSheetStartDate'];
      }
      // clear pdfCustomerName if changing pricing sheet type to a type with free input
      if (
        propName === 'priceSheetType' &&
        (value === 'Jäsenhinnasto' || value === 'Referenssi')
      ) {
        newState.pdfCustomerName = null;
      }
      setBackgroundInfo(newState);
    };
  };

  const handleTextFieldChange = useCallback(
    (field: 'sellerName' | 'sellerEmail' | 'sellerPhoneNumber') =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setBackgroundInfo({
          ...backgroundInfo,
          [field]: event.target.value,
        });
      },
    [backgroundInfo, setBackgroundInfo],
  );

  useEffect(() => {
    if (
      window.location.pathname === '/muokkaa-hinnastoa' &&
      !backgroundInfo?.customerName
    ) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      backgroundInfo?.customerName &&
      backgroundInfo?.priceSheetStartDate &&
      backgroundInfo?.priceSheetEndDate &&
      backgroundInfo?.priceSheetType
    ) {
      const site =
        backgroundInfo?.site &&
        backgroundInfo?.priceSheetType === 'Projekti'
          ? `_${backgroundInfo?.site?.replace(/\s/g, '')}`
          : '';
      const customerName = backgroundInfo?.customerName?.replace(
        /\s/g,
        '',
      );
      const name = `${customerName}${site}_${backgroundInfo?.priceSheetStartDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '')}-${backgroundInfo?.priceSheetEndDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '')}_${getPriceSheetTypeAbbreviation(
        backgroundInfo?.priceSheetType,
      )}`;

      setName(name);
    } else {
      setName(undefined);
    }
  }, [backgroundInfo]);

  useEffect(() => {
    setBackgroundInfo({
      ...backgroundInfo,
      name: name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handlers = <T,>(propName: string) => {
    return {
      value: backgroundInfo[propName],
      onChange: handlePropChange<T>(propName),
    };
  };

  useEffect(() => {
    if (!pricingSheetId) {
      resetBackgroundInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingSheetId]);

  useEffect(() => {
    return () => {
      setUpdateInitialInfoEnabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange: HandleDateChange = (value) => {
    const newState = { ...backgroundInfo };
    if (value) {
      value = setDatetoStartOfUTCDay(value);
    }
    newState['priceSheetStartDate'] = value;

    // ensure that end date is not before start date
    const startDate = newState['priceSheetStartDate'];
    const endDate = newState['priceSheetEndDate'];

    if (endDate < startDate) {
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'warning',
        message: 'Päättymispäivä ei voi olla ennen alkamispäivää',
      });
      newState['priceSheetEndDate'] = newState['priceSheetStartDate'];
    }

    setBackgroundInfo(newState);
  };

  const handleEndDateChange: HandleDateChange = (value) => {
    const newState = { ...backgroundInfo };

    newState['priceSheetEndDate'] =
      value && setDatetoStartOfUTCDay(value);
    setBackgroundInfo(newState);
  };

  return (
    <>
      <Header backgroundInfo={backgroundInfo} />
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={6}
        >
          {/** FIRST COLUMN */}
          <Grid item xl={3} lg={6} xs={12}>
            {/** CUSTOMER SELECTION */}
            <BackgroundInfoInputLabel label="Asiakas*" />
            <CustomerSearchSelector
              customerName={backgroundInfo.customerName}
              customerId={backgroundInfo.customerId}
              onCustomerChange={(clientOption) => {
                setBackgroundInfo({
                  ...backgroundInfo,
                  customerName: clientOption?.value,
                  pdfCustomerName: clientOption?.value,
                  customerId: clientOption?.id,
                });
              }}
            />
            {backgroundInfo?.priceSheetType === 'Referenssi' ||
            backgroundInfo?.priceSheetType === 'Jäsenhinnasto' ? (
              <CustomerWriteSelector />
            ) : (
              <>
                <CustomerWriteSelector
                  field={'pdfCustomerName'}
                  label={'Asiakkaan nimi hinnaston kannessa'}
                  sx={{
                    mb: 1,
                  }}
                />
              </>
            )}
            {backgroundInfo?.priceSheetType === 'Projekti' && (
              <Site />
            )}
            {/** CUSTOMER SEGMENT */}
            <BackgroundInfoInputLabel label="Asiakkaan toimiala*" />
            <IndustrySelector
              changeValuesEnabled={updateInitialInfoEnabled}
              pricingSheetId={pricingSheetId}
              industry={backgroundInfo?.industry}
              onIndustryChange={(newValue: Nullable<string>) => {
                setBackgroundInfo({
                  ...backgroundInfo,
                  industry: newValue ?? undefined,
                });
              }}
            />
            {/** CUSTOMER REGION */}
            <BackgroundInfoInputLabel label="Alue*" />
            <RegionSelector />
            <GenericCheckbox
              label={'Toimii valtakunnallisesti'}
              propName={'nationWide'}
            />
            <GenericCheckbox
              label={'Alueellisesti merkittävä kohde'}
              propName={'regionallySignificant'}
            />
            <GenericCheckbox
              label={'Strategisesti merkittävä kohde'}
              propName={'strategicallySignificant'}
            />
          </Grid>
          {/** SECOND COLUMN */}
          <Grid item xl={3} lg={6} xs={12}>
            {/** PRICING SHEET LEVEL */}
            <BackgroundInfoInputLabel label="Hinnaston taso*" />
            <Level handlers={handlers} />
            {/** SELLER INFORMATION */}{' '}
            <Box
              display={'flex'}
              flexDirection="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <BackgroundInfoInputLabel label="Ramirentin yhteyshenkilö" />
              <DefaultTooltip
                title={
                  'Yhteyshenkilön tiedot näkyvät hinnaston tulosteella'
                }
                placement="top"
              >
                <InfoOutlined
                  color={'info'}
                  sx={{
                    cursor: 'help',
                    fontSize: 20,
                    ml: 1,
                    mt: 0.5,
                  }}
                />
              </DefaultTooltip>
            </Box>
            <TextField
              fullWidth={true}
              size={'small'}
              label={'Nimi'}
              value={sellerName}
              onChange={handleTextFieldChange('sellerName')}
              sx={{
                my: 1,
              }}
            />
            <TextField
              fullWidth={true}
              size={'small'}
              type={'email'}
              value={sellerEmail}
              error={
                sellerEmail?.length > 0 && !isValidEmail(sellerEmail)
              }
              helperText={
                sellerEmail?.length > 0 && !isValidEmail(sellerEmail)
                  ? 'Varmista että sähköpostiosoite on oikein'
                  : null
              }
              label={'Sähköposti'}
              onChange={handleTextFieldChange('sellerEmail')}
              sx={{
                my: 1,
              }}
            />
            <TextField
              fullWidth={true}
              size={'small'}
              label={'Puhelinnumero'}
              value={sellerPhoneNumber}
              onChange={handleTextFieldChange('sellerPhoneNumber')}
              sx={{
                my: 1,
              }}
            />
          </Grid>
          {/** THIRD COLUMN */}
          <Grid item xl={5} lg={6} xs={12}>
            {/** CUSTOMER REVENUE */}
            <Box
              display={'flex'}
              flexDirection="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <BackgroundInfoInputLabel label="Asiakkaan liikevaihto*" />
              <DefaultTooltip
                title="Arvio asiakkaan liikevaihdosta"
                placement="top"
              >
                <InfoOutlined
                  color={'info'}
                  sx={{
                    cursor: 'help',
                    fontSize: 20,
                    ml: 1,
                    mt: 0.5,
                  }}
                />
              </DefaultTooltip>
            </Box>
            <Revenue changeValuesEnabled={updateInitialInfoEnabled} />
            {/** CUSTOMER POTENTIAL */}
            <Box
              display={'flex'}
              flexDirection="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <BackgroundInfoInputLabel label="Arvio koko vuokrapotentiaalista vuodessa*" />
              <DefaultTooltip
                title={
                  'Arvio asiakkaan koko vuokrapotentiaalista kaikilta toimijoilta'
                }
                placement="top"
              >
                <InfoOutlined
                  color={'info'}
                  sx={{
                    cursor: 'help',
                    fontSize: 20,
                    ml: 1,
                    mt: 0.5,
                  }}
                />
              </DefaultTooltip>
            </Box>
            <Potential
              changeValuesEnabled={updateInitialInfoEnabled}
            />
            {/** CUSTOMER COMMITMENT */}
            <Commitment
              backgroundInfo={backgroundInfo}
              changeValuesEnabled={updateInitialInfoEnabled}
            />
            {pricingSheetId && (
              <ChangeValuesButton
                changeValuesEnabled={updateInitialInfoEnabled}
                setChangeValuesEnabled={setUpdateInitialInfoEnabled}
              />
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'flex-end'}
            >
              <AcceptanceMatrix />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 1, width: '100%' }} />
        {/** BOTTOM ROW */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Box>
            {/** PRICING SHEET VALIDITY PERIOD */}
            <BackgroundInfoInputLabel label="Hinnaston voimassaolo*" />
            <Dates
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              minStartDate={new Date()}
              startDate={backgroundInfo.priceSheetStartDate}
              endDate={backgroundInfo.priceSheetEndDate}
              startDateLabel={'Hinnasto voimassa alkaen'}
              endDateLabel={'Hinnaston päättymispäivä'}
            />
          </Box>
          {/** CONTRACT DURATION */}
          <Box>
            <BackgroundInfoInputLabel label="Sopimuksen kesto" />
            <Duration backgroundInfo={backgroundInfo} />
          </Box>
          {/** CONTRACT TOTAL VALUE */}
          <Box>
            <BackgroundInfoInputLabel label="Sopimuksen arvo" />
            <Value backgroundInfo={backgroundInfo} />
          </Box>
          {/** PRICING SHEET PAYMENT TERM */}
          <Box>
            <BackgroundInfoInputLabel label="Maksuehto*" />
            <PaymentTermSelector />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InitialInformationStep;
