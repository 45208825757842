import React from 'react';
import { Box } from '@mui/system';
import GenericCheckbox from './Checkboxes/GenericCheckbox';
import RevenueSlider from './Sliders/RevenueSlider';
import { initRevenue } from '../../../providers/Background/BackgroundProvider';

interface RevenueProps {
  changeValuesEnabled: boolean;
}
const Revenue: React.FC<RevenueProps> = ({ changeValuesEnabled }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <GenericCheckbox
          label={'Liikevaihto yli miljoona'}
          propName={'revenueOverMillion'}
          changeValuesEnabled={changeValuesEnabled}
        />
      </Box>
      <Box>
        <RevenueSlider
          initial={initRevenue}
          changeValuesEnabled={changeValuesEnabled}
        />
      </Box>
    </>
  );
};

export default Revenue;
