import React, { useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';

const days = [
  { value: 14, label: '14 päivää' },
  { value: 21, label: '21 päivää' },
  { value: 30, label: '30 päivää' },
  { value: 45, label: '45 päivää' },
  { value: 60, label: '60 päivää' },
];

const PaymentTermSelector: React.FC = () => {
  type PaymentTerm = {
    value: number;
    label: string;
  };

  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  const updateBackgroundInfo = (label: string) => {
    const day = days.find((d) => d.label === label)?.value;
    setBackgroundInfo({
      ...backgroundInfo,
      paymentTermDays: day,
    });
  };

  const getDaysObject = (daysAsNumber: number) => {
    const dayObject = days.find((d) => d.value === daysAsNumber);
    if (dayObject) {
      return dayObject as PaymentTerm;
    }
    return undefined;
  };
  return (
    <Autocomplete
      size="small"
      value={getDaysObject(backgroundInfo.paymentTermDays)}
      onInputChange={(event, newInputValue) => {
        updateBackgroundInfo(newInputValue);
      }}
      id="controllable-days"
      options={days}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Valitse maksuehto..." />
      )}
    />
  );
};

export default PaymentTermSelector;
