import { useQuery } from '@tanstack/react-query';
import { api } from '../../..';
import { PRODUCT_LISTS_QUERY_KEY } from '../../../../../shared/constants';
import {
  TableColumn,
  TableSortOrder,
} from '../../../../../shared/types';
import {
  DataTable,
  DataTableProps,
} from '../../../components/Common/DataTable/DataTable';
import { Button, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useState } from 'react';
import { EditProductListDialog } from './EditProductListDialog';
import { DataTableRowActionsContainer } from '../../../components/Common/DataTable/DataTableRowActions';
import EditIcon from '@mui/icons-material/Edit';
import DefaultTooltip from '../../../components/Tooltips/DefaultTooltip';
import { SimpleProductListView } from './SimpleProductListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProductListTooltip from '../../../components/Tooltips/ProductListTooltip';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import {
  DataBaseProductListItemWithItem,
  GetProductListsResponseBody,
} from '../../../../../shared/types/productLists';
import CloseIcon from '@mui/icons-material/Close';
import { deleteProductListRequest } from '../../../services/productLists';

type ProductListTableRow = {
  id: number;
  name: string;
  description: string;
  productCount: number;
  productListItems: DataBaseProductListItemWithItem[];
};

const TABLE_COLUMNS: TableColumn<ProductListTableRow>[] = [
  { key: 'id', label: 'Listan Id' },
  { key: 'name', label: 'Nimi' },
  { key: 'description', label: 'Selite' },
  { key: 'productCount', label: 'Tuotteiden määrä' },
];

const ProductListTable = () => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [productListData, setProductListData] = useState<
    ProductListTableRow | undefined
  >(undefined);

  const {
    data: productLists = [],
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [PRODUCT_LISTS_QUERY_KEY],
    queryFn: async () =>
      (await api.get<GetProductListsResponseBody>(`/productlists`))
        .data,
    staleTime: Infinity,
  });
  const { setNotification } = useContext(NotificationProvider);

  const formatProductList = (productList: any) => {
    return {
      ...productList,
      productCount: productList.productListItems.length,
    };
  };

  const handleDelete = async (id: string, name: string) => {
    setNotification({
      type: 'MODAL',
      cancelText: 'Peruuta',
      acceptText: 'Poista',
      severity: 'error',
      message: `Oletko varma että haluat poistaa tuotelistan "${name}"`,
      title: 'Olet poistamassa tuotelistaa',
      onAccept: async () => {
        await deleteProductListRequest(id);
        await refetch();
      },
    });
  };

  const onConfirm = () => {
    setEditDialogOpen(false);
    setProductListData(undefined);
    refetch();
  };

  const onCancel = (isDirty: boolean) => {
    if (isDirty) {
      setNotification({
        type: 'MODAL',
        cancelText: 'Peruuta',
        acceptText: 'Sulje',
        severity: 'info',
        message: `Oletko varma että haluat sulkea listan?`,
        title: 'Listalla on tallentamattomia muutoksia',
        onAccept: () => {
          setEditDialogOpen(false);
          setProductListData(undefined);
        },
      });
    } else {
      setEditDialogOpen(false);
      setProductListData(undefined);
    }
  };

  return (
    <>
      {editDialogOpen && (
        <EditProductListDialog
          open={editDialogOpen}
          cancelButtonLabel="Sulje"
          confirmButtonLabel="Tallenna"
          title={
            productListData
              ? 'Muokkaa tuotelistaa'
              : 'Luo uusi tuotelista'
          }
          onCancel={onCancel}
          onConfirm={onConfirm}
          productListData={productListData}
        />
      )}
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignContent={'center'}
      >
        <h3>Tuotelistat</h3>
        <Button
          onClick={() => {
            setEditDialogOpen(true);
          }}
          sx={{ height: 30, alignSelf: 'center' }}
          size="small"
          variant="contained"
          startIcon={<AddIcon />}
        >
          Uusi tuotelista
        </Button>
      </Grid>
      <DataTable
        count={productLists.length}
        labelNoResults={'Tuotelistoja ei löytynyt!'}
        labelRowsPerPage={'Tuotelistoja per sivu'}
        items={productLists
          .sort((a, b) => {
            return b.id - a.id;
          })
          .map(formatProductList)}
        paginationOptions={{ offset: 0, limit: 100 }}
        renderColumnFilter={ProductListTableColumnFilter}
        sortOptions={{
          direction: TableSortOrder.Desc,
          orderBy: 'id',
        }}
        tableColumns={TABLE_COLUMNS}
        filterOptions={{}}
        loading={isFetching}
        renderCellComponent={ProductListTableCellComponent}
        renderRowActions={ProductListTableRowActions(
          setProductListData,
          setEditDialogOpen,
          handleDelete,
        )}
      />
    </>
  );
};

const ProductListTableRowActions =
  (
    setProductListData: (item: ProductListTableRow) => void,
    setEditDialogOpen: (open: boolean) => void,
    handleDelete: (id: string, name: string) => void,
  ): DataTableProps<ProductListTableRow>['renderRowActions'] =>
  ({ item }): JSX.Element => {
    return (
      <DataTableRowActionsContainer
        sx={{
          justifyContent: 'start',
          px: 1,
        }}
      >
        <DefaultTooltip placement="top" title="Muokkaa">
          <IconButton
            onClick={() => {
              setProductListData(item);
              setEditDialogOpen(true);
            }}
            color="primary"
            size="small"
            children={<EditIcon />}
          />
        </DefaultTooltip>
        <DefaultTooltip placement="top" title="Poista">
          <IconButton
            onClick={() => {
              handleDelete(String(item.id), item.name);
            }}
            color="error"
            size="small"
            children={<CloseIcon />}
          />
        </DefaultTooltip>
      </DataTableRowActionsContainer>
    );
  };

const ProductListTableCellComponent = ({
  item,
  column,
}: {
  item: ProductListTableRow;
  column: TableColumn<ProductListTableRow>;
}) => {
  if (column.key === 'productCount') {
    return (
      <Grid container flexDirection={'row'} alignContent={'center'}>
        {item[column.key]}
        <ProductListTooltip
          title={
            <SimpleProductListView
              items={item.productListItems.map((productListItem) => {
                return {
                  ...productListItem.item,
                  amount: productListItem.amount,
                };
              })}
            />
          }
        >
          <InfoOutlinedIcon
            color="info"
            sx={{ fontSize: 18, cursor: 'help', ml: 1 }}
          />
        </ProductListTooltip>
      </Grid>
    );
  }
  return <span>{item[column.key]}</span>;
};
const ProductListTableColumnFilter = () => {
  return <></>;
};

export default ProductListTable;
